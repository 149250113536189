import { useQuery } from '@tanstack/react-query';
import { profile } from '../requests/auth';

const KEY = 'auth';

const authQuery = {
  useProfile: () => useQuery({
    queryKey: [KEY, 'profile'],
    queryFn: () => profile(),
    cacheTime: 0,
    staleTime: 0,
  }),
};

export default authQuery;
