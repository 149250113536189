export default {
  title: 'יצירת סוכן',
  more: 'צפה עוד',
  menu: {
    details: 'פרטים',
    contracts: 'הסכמים',
    documents: 'מסמכים',
    clients: 'תיקים',
  },
  status: {
    active: 'פעיל',
    archive: 'ארכיון',
  },
  side: {
    actions: 'פעולות',
  },
  headers: {
    private: 'פרטים אישיים',
    contact: 'פרטי קשר',
    home: 'בית',
    office: 'משרד',
    status: 'סטטוס',
    action: 'פעולות',
    history: 'היסטוריה',
    comment: 'הערות',
    contracts: 'הסכמים',
    extra: 'מידע נוסף',
    family: 'משפחה',
    dates: 'תאריכים',
    documents: 'מסמכים',
    clients: 'ניהול מספרי תיק/יישות',
  },
  agentType: {
    CTO: 'מנכ"ל',
    houseAgent: 'סוכן בית',
    integratedAgent: 'סוכן משולב',
    foreignAgent: 'סוכן חוץ',
  },
  employeeType: {
    employee: 'שכיר',
    selfEmployee: 'עצמאי',
  },
  notification: {
    success: {
      title: 'הסוכן נוצר בהצלחה',
      message: 'פרטי הסוכן נוצרו במערכת',
    },
    failure: {
      title: 'סוכן לא נוצר',
      message: 'אירעה שגיאה בניסיון היצירה',
    },
    validtion: {
      title: 'סוכן לא נוצר',
      message: 'אחד מהפרטים שהוזנו איזו תקין',
    },
  },
};
