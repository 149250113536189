import { headers as defaultHeaders } from '@/config/axios';
import axiosRequest from '.';

const importData = async (data) => (
  axiosRequest({
    data,
    url: '/v1/docs/import',
    method: 'POST',
  })
);

const exportTableData = async (data) => (
  axiosRequest({
    data,
    url: '/v1/docs/export/table',
    method: 'POST',
    headers: defaultHeaders,
  })
);

const exportChartData = async (data) => (
  axiosRequest({
    data,
    url: '/v1/docs/export/chart',
    method: 'POST',
    headers: defaultHeaders,
  })
);

const exportFile = async (data) => (
  axiosRequest({
    data,
    url: '/v1/docs/export/file',
    method: 'POST',
    headers: defaultHeaders,
  })
);

export {
  importData,
  exportTableData,
  exportChartData,
  exportFile,
};
