import { useMutation } from '@tanstack/react-query';
import {
  exportTableData, exportChartData, importData, exportFile,
} from '../requests/docs';

const KEY = 'company';

const docsMutation = {
  useImport: () => useMutation({
    mutationKey: [KEY, 'import'],
    mutationFn: (data) => importData(data),
  }),
  useExportTable: () => useMutation({
    mutationKey: [KEY, 'export', 'table'],
    mutationFn: (data) => exportTableData(data),
  }),
  useExportChart: () => useMutation({
    mutationKey: [KEY, 'export', 'chart'],
    mutationFn: (data) => exportChartData(data),
  }),
  useExportFile: () => useMutation({
    mutationKey: [KEY, 'export', 'file'],
    mutationFn: (data) => exportFile(data),
  }),
};

export default docsMutation;
