import PropTypes from 'prop-types';
import { IconSelect, IconSquareChevronUp, IconTrash } from '@tabler/icons-react';
import {
  ActionIcon,
  Text,
  Grid,
  UnstyledButton,
  Container,
} from '@mantine/core';
import styles from './style.module.css';

const CustomCollapse = (props) => {
  const {
    title,
    children,
    onDelete,
    onClick,
    open,
  } = props;

  return (
    <div className={styles.collapse_container}>
      <UnstyledButton className={styles.collapse_button} p='sm' onClick={onClick}>
        <Grid className={styles.collapse_} align='center'>
          <Grid.Col span={1}>
            <ActionIcon
              onClick={onDelete}
              color={'red'}
              variant={'transparent'}
            >
              <IconTrash />
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={10}>
            <Text size="md" weight={500}>{title}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            {
              open ? (
                <IconSquareChevronUp
                  className={styles.collapse_arrow}
                  display={'block'}
                />
              ) : (
                <IconSelect
                  className={styles.collapse_arrow}
                  display={'block'}
                />
              )
            }
          </Grid.Col>
        </Grid>
      </UnstyledButton>
      {
        open && (
        <Container className={styles.collapse_content} p='sm'>
          {children}
        </Container>
        )
      }
    </div>
  );
};

CustomCollapse.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  open: PropTypes.bool,
};

export default CustomCollapse;
