import prepareTranslate from '@/helpers/dictionary';
import PageHead from '@/components/PageHead';
import { Button, Container } from '@mantine/core';
import routes from '@/routes';
import {
  IconEdit,
  IconEye,
  IconPlus,
  IconX,
} from '@tabler/icons-react';
import Table from '@/components/Table';
import { useNavigate } from 'react-router-dom';
import { routeWithParams } from '@/helpers/route';
import userMutations from '@/api/mutitations/user';
import { notifications } from '@mantine/notifications';
import docsMutation from '@/api/mutitations/docs';
import { downloadFile } from '@/helpers/download';

const Users = () => {
  const navigate = useNavigate();
  const t = prepareTranslate();
  const { useGetUsers, useUpdateUser, useDeleteManyUsers } = userMutations;
  const { mutateAsync: exportFileMutate } = docsMutation.useExportFile();

  const usersQuery = useGetUsers();
  const { mutateAsync: updateUserMutate } = useUpdateUser();
  const { mutateAsync: deleteMutate } = useDeleteManyUsers();

  // eslint-disable-next-line consistent-return
  const getData = async (start, end, query) => {
    try {
      return usersQuery.mutateAsync({ start, end, query });
    } catch (error) {
      notifications.show({
        title: t('pages.users.notification.errors.find.title'),
        message: t('pages.users.notification.errors.find.message'),
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching users:', error);
    }
  };
  const handleClickOnView = (id) => {
    navigate(routeWithParams(routes.userView.path, { id }));
  };

  const handleClickOnEdit = (id) => {
    navigate(routeWithParams(routes.userEdit.path, { id }));
  };

  const handleCreateClick = () => {
    navigate(routes.userCreate.path);
  };
  const handleUnBlockClick = async (id) => {
    const details = {
      isBlocked: false,
      id,
    };
    await updateUserMutate(details);
  };
  const handleClickOnDelete = async (items = []) => {
    try {
      await deleteMutate({ data: items });

      // TODO reload page in better way
      // refreshRows(items, 'delete');
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    } catch {
      notifications.show({
        title: t('pages.users.notification.errors.delete.title'),
        message: t('pages.users.notification.errors.delete.message'),
      });
    }
  };

  const handleExport = async (option, query) => {
    const requestColumns = mockColumns.filter((column) => column.header !== 'פעולות');
    const exportData = {
      query,
      fileType: option,
      service: 'user',
      columns: requestColumns,
      titleArray: ['firstName'],
    };

    const fileTitleBase = t('pages.users.fileTitle');
    const fileTitle = option === 'pdf' ? `${fileTitleBase}.pdf` : `${fileTitleBase}.xlsx`;

    try {
      const fileData = await exportFileMutate(exportData);
      downloadFile(fileData, fileTitle);
    } catch (error) {
      notifications.show({
        title: t('pages.users.notification.errors.export.title'),
        message: t('pages.users.notification.errors.export.message'),
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching users:', error);
    }
  };

  const mockColumns = [
    {
      header: 'שם פרטי',
      accessor: 'firstName',
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'שם משפחה',
      accessor: 'lastName',
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'דואר אלקטרוני',
      accessor: 'email',
      transforms: ['email'],
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'טלפון',
      accessor: 'phone',
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'חסום',
      accessor: 'isBlocked',
      transforms: ['yesno'],
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'התחבר לאחרונה',
      accessor: 'lastLoginAttempt',
      transforms: ['date'],
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'ארכיון',
      accessor: 'isArchived',
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'תפקיד',
      accessor: 'role',
      transforms: [
        {
          func: 'badge',
          params: {
            rules: [
              { byValue: 'administrator', color: 'blue' },
              { byValue: 'superadmin', color: 'violet' },
            ],
          },
        },
      ],
      filter: {
        type: 'string',
      },
      isVisible: true,
    },
    {
      header: 'פעולות',
      filter: {
        enable: false,
      },
      actions: [
        {
          title: 'צפייה',
          action: handleClickOnView,
          icon: <IconEye />,
          showLabel: true,
          isPrimary: true,
        },
        {
          title: 'עריכה',
          action: handleClickOnEdit,
          icon: <IconEdit />,
          showLabel: false,
          isPrimary: false,
        },
        // TODO: block/unblock user.
        {
          title: 'הסרת חסימה',
          action: handleUnBlockClick,
          icon: <IconX />,
          showLabel: false,
          isPrimary: false,
        },
      ],
    },
  ];

  const mockSelectionActions = [
    {
      title: 'מחיקה',
      icon: <IconX stroke='1' />,
      onClick: handleClickOnDelete,
    },
  ];

  const exportOptions = ['excel'];

  return (
    <>
      <PageHead
        title={routes.users.name}
        actions={
          <Button leftSection={<IconPlus />} onClick={handleCreateClick}>
            {t('pages.users.action')}
          </Button>
        }
      />

      <Container fluid>
        <Table
          columns={mockColumns}
          onExport={handleExport}
          getData={getData}
          selectionActions={mockSelectionActions}
          isColumnsActive={false}
          exportOptions={exportOptions}
          isExportActive
        />
      </Container>
    </>
  );
};

export default Users;
