import { headers as defaultHeaders } from '@/config/axios';
import axiosRequest from '.';

const getByCompanyId = async (data) => (
  axiosRequest({
    url: '/v1/importRule/findByCompanyId',
    method: 'POST',
    data,
    headers: defaultHeaders,
  })
);

export {
  getByCompanyId,
};
