export default {
  title: 'עמלות סוכנות',
  action: 'הוסף עמלות סוכנות',
  fileTitle: 'עמלות סוכנות',
  notification: {
    errors: {
      find: {
        title: 'שגיאה',
        message: 'לא ניתן למצוא עמלות סוכנות אנא נסה שנית',
      },
      delete: {
        title: 'שגיאה',
        message: 'לא ניתן למחוק עמלות סוכנות אנא נסה שנית',
      },
      export: {
        title: 'שגיאה',
        message: 'לא ניתן להפיק דו"ח אנא נסה שנית',
      },
    },
  },
};
