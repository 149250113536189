export default {
  firstname: 'שם פרטי',
  lastname: 'שם משפחה',
  fullName: 'שם מלא',
  agentType: 'תפקיד',
  uid: 'תעודת זהות',
  dob: 'תאריך לידה',
  email: 'דואר אלקטרוני',
  phone: 'טלפון',
  cellphone: 'נייד',
  extracellphone: 'נייד נוסף',
  website: 'אתר אנטרנט',
  gender: 'מין',
  male: 'זכר',
  female: 'נקבה',
  homeaddress: 'כתובת מלאה',
  city: 'עיר',
  employeeType: 'סוג סוכן',
  street: 'רחוב',
  houseNumber: 'מספר בית',
  zipCode: 'מיקוד',
  postalCode: 'ת.ד',
  homephone: 'טלפון',
  officeaddress: 'כתובת מלאה',
  officephone: 'טלפון',
  status: 'סטטוס',
  familystatus: 'מצב משפחתי',
  spouse: 'שם בן / בת זוג',
  numberOfChildren: 'מספר ילדים',
  haveChildren: 'יש ילדים?',
  addChildren: 'הוספת ילד',
  policyend: 'תאריך תום פוליסה אחריות מקצועית',
  licenseend: 'תאריך תום רשיון',
  tax: 'תאריך ניכוי מס וניהול ספרים',
  professionalPolicy: 'תאריך פוליסה מקצועית',
};
