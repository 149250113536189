import PropTypes from 'prop-types';
import { IconTrash, IconDownload } from '@tabler/icons-react';
import { ActionIcon, Table } from '@mantine/core';
import { formatDateToString, truncateString } from '@/helpers/string';
import styles from './style.module.css';
import UserIcon from '../UserIcon';
import CustomDropZone from '../CustomDropZone';

const Files = (props) => {
  const {
    files,
    handleDelete = () => {},
    handleAccept = () => {},
    handleDownload = () => {},
    enableDelete = false,
    enableDownload = false,
    showForm = true,
  } = props;

  const rows = files?.map((item, index) => {
    const {
      _id,
      fileName,
      fileType = '',
      createdAt,
      uploadedBy,
      name,
    } = item;

    // const handleDownLoad
    const formattedFilename = truncateString(fileName || name, 20, 20);
    const today = new Date();

    return (
      <Table.Tr key={_id || index + 1}>
        <Table.Td>{formattedFilename}</Table.Td>
        <Table.Td>{fileType}</Table.Td>
        <Table.Td>{formatDateToString(createdAt) || formatDateToString(today)}</Table.Td>
        <Table.Td>
          <UserIcon
            userId={uploadedBy}
            isMenuActive={true}
            showOnlyDetails={true}
          />
        </Table.Td>
        <Table.Td>
          {enableDelete && (
            <ActionIcon
              mb={10}
              ml={10}
              color={'red'}
              variant={'transparent'}
              onClick={() => {
                handleDelete(index);
              }}
            >
              <IconTrash />
            </ActionIcon>
          )}
         {enableDownload && (
           <ActionIcon
              mb={10}
              variant={'transparent'}
              onClick={() => {
                handleDownload(_id);
              }}
            >
            <IconDownload />
          </ActionIcon>
         )
            }
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <div className={styles.wrapper}>
      {
        showForm && (
          <CustomDropZone
            handleAccept={handleAccept}
          />
        )
      }

      <Table verticalSpacing="lg">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>שם קובץ</Table.Th>
            <Table.Th>סוג</Table.Th>
            <Table.Th>תאריך העלאה</Table.Th>
            <Table.Th>הועלה על ידי</Table.Th>
            {(enableDelete || enableDownload) && (
              <Table.Th></Table.Th>
            )}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  );
};

Files.propTypes = {
  type: PropTypes.oneOf(['agent', 'agency', 'user']),
  id: PropTypes.string,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDownload: PropTypes.func,
  handleAccept: PropTypes.func,
  showForm: PropTypes.bool,
  enableDelete: PropTypes.bool,
  enableDownload: PropTypes.bool,
};

export default Files;
