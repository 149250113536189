export default {
  fullname: 'שם מלא',
  uid: 'תעודת זהות',
  familyStatus: 'מצב משפחתי',
  dob: 'תאריך לידה',
  gender: 'מין',
  spouse: 'שם בן / בת זוג',
  numberOfChildren: 'מספר ילדים',
  employeeType: 'סוג סוכן',
  email: 'דואר אלקטרוני',
  phone: 'טלפון',
  cellphone: 'נייד',
  extracellphone: 'נייד נוסף',
  website: 'אתר אנטרנט',
  homeaddress: 'כתובת מלאה',
  homephone: 'טלפון',
  officeaddress: 'כתובת מלאה',
  officephone: 'טלפון',
  policyend: 'תאריך תום פוליסה אחריות מקצועית',
  licenseend: 'תאריך תום רשיון',
  tax: 'תאריך ניכוי מס וניהול ספרים',
  professionalPolicy: 'תאריך פוליסה מקצועית',
  clientNumber: 'מספר תיק/יישות',
  clientName: 'שם תיק/יישות',
  hp: 'ח.פ',
  headers: {
    private: 'פרטים אישיים',
    contact: 'פרטי קשר',
    home: 'בית',
    office: 'משרד',
    family: 'משפחה',
    dates: 'תאריכים',
  },
};
