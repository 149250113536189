import { headers as defaultHeaders } from '@/config/axios';
import axiosRequest from '.';

const getAgentsByQuery = async ({ start, end, query }) => (
  axiosRequest({
    url: '/v1/agent/agents',
    method: 'POST',
    data: { start, end, query },
    headers: defaultHeaders,
  })
);

const getFilteredAgentsByQuery = async (data = {}) => (
  axiosRequest({
    url: '/v1/agent/filter',
    method: 'POST',
    data,
    headers: defaultHeaders,
  })
);

const getAgentById = async (data = {}) => (
  axiosRequest({
    url: '/v1/agent/agent',
    method: 'POST',
    data,
    headers: defaultHeaders,
  })
);

const createAgent = async (data = {}) => (
  axiosRequest({
    url: '/v1/agent/create',
    method: 'POST',
    data,
    headers: defaultHeaders,
  })
);

const updateAgent = async (data = {}) => (
  axiosRequest({
    url: '/v1/agent/update',
    method: 'POST',
    data,
    headers: defaultHeaders,
  })
);

const archiveAgent = async (data = {}) => (
  axiosRequest({
    url: 'v1/agent/archive',
    method: 'POST',
    data,
    headers: defaultHeaders,
  })
);

export {
  getAgentsByQuery,
  getFilteredAgentsByQuery,
  getAgentById,
  createAgent,
  updateAgent,
  archiveAgent,
};
