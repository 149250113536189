import prepareTranslate from '@/helpers/dictionary';
import { ACCORD_TYPE } from '@/helpers/enums';

const t = prepareTranslate();

const validations = {
  companyOrHp: (values, path) => {
    let hpFieldValue = null;
    let companyFieldValue = null;

    if (!path) {
      return null;
    }

    const clientIndex = Number(path.split('.')[1]);

    const relevantClient = values.clients[clientIndex];

    const hpFieldEntries = Object.entries(relevantClient).find(([key]) => {
      if (key.includes('hp')) {
        return true;
      }
      return false;
    });

    const companyFieldEntries = Object.entries(relevantClient).find(([key]) => {
      if (key.includes('companyId')) {
        return true;
      }
      return false;
    });

    if (hpFieldEntries) {
      [, hpFieldValue] = hpFieldEntries;
    }

    if (companyFieldEntries) {
      [, companyFieldValue] = companyFieldEntries;
    }

    if (!companyFieldValue && !hpFieldValue) {
      return t('validations.pages.agentEdit.companyOrHp');
    }

    return null;
  },
  requireEmployeeCommission: (value, values, path) => {
    let accordTypeFieldValue = null;
    const emptyValue = value === '' ? null : value;

    if (!path) {
      return null;
    }

    const clientIndex = Number(path.split('.')[1]);
    const relevantClient = values.clients[clientIndex];

    const accordTypeFieldEntries = Object.entries(relevantClient).find(([key]) => {
      if (key.includes('accordType')) {
        return true;
      }
      return false;
    });

    if (accordTypeFieldEntries) {
      [, accordTypeFieldValue] = accordTypeFieldEntries;
    }

    if (accordTypeFieldValue === ACCORD_TYPE[1].value && !emptyValue) {
      return t('validations.required');
    }

    return null;
  },
  requireIndependentCommission: (value, values, path) => {
    let accordTypeFieldValue = null;
    const emptyValue = value === '' ? null : value;

    if (!path) {
      return null;
    }

    const clientIndex = Number(path.split('.')[1]);
    const relevantClient = values.clients[clientIndex];

    const accordTypeFieldEntries = Object.entries(relevantClient).find(([key]) => {
      if (key.includes('accordType')) {
        return true;
      }
      return false;
    });

    if (accordTypeFieldEntries) {
      [, accordTypeFieldValue] = accordTypeFieldEntries;
    }

    if (accordTypeFieldValue === ACCORD_TYPE[0].value && !emptyValue) {
      return t('validations.pages.required');
    }

    return null;
  },
};

export default validations;
