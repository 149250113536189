// import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import PropTypes from 'prop-types';
import uploadMutations from '@/api/mutitations/upload';
import prepareTranslate from '@/helpers/dictionary';
import Files from '../Files';
import PageTemplate from '../PageTemplate';
import Section from '../Section';

function ViewAgentFiles(props) {
  const {
    open,
    close,
    files,
  } = props;

  const t = prepareTranslate();

  // const { id } = useParams();
  const downloadById = uploadMutations.useDownload();

  const handleDownload = async (fileId) => {
    try {
      open();
      notifications.show({
        title: t('components.viewAgentFiles.notification.download.title'),
        message: t('components.viewAgentFiles.notification.download.message'),
      });
      const response = await downloadById.mutateAsync({ fileId });
      const { file, contentType, fileName } = response;

      // Decode base64 string to binary
      const byteCharacters = atob(file);
      const byteNumbers = new Array(byteCharacters.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: contentType });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;

      // Trigger the download
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      close();
    } catch (error) {
      open();
      notifications.show({
        title: t('components.viewAgentFiles.notification.downloadFaild.title'),
        message: t('components.viewAgentFiles.notification.downloadFaild.message'),
      });
      close();
    }
  };
  return (
    <PageTemplate.Panel id='documents'>
      <Section title={t('components.viewAgentFiles.headers.documents')}>
        <Files
          files={files}
          showForm={false}
          handleDownload={handleDownload}
          enableDownload
        />
      </Section>
  </PageTemplate.Panel>
  );
}

ViewAgentFiles.propTypes = {
  open: PropTypes.func,
  close: PropTypes.func,
  files: PropTypes.array,
};

export default ViewAgentFiles;
