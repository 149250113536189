import { headers as defaultHeaders } from '@/config/axios';
import axiosRequest from '.';

const profile = async () => (
  axiosRequest({
    url: '/v1/auth/profile',
    method: 'POST',
    headers: defaultHeaders,
  })
);

export {
  profile,
};
