import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const RESULT_GET_ALL = {
  default: {
    response: {
      object: 'getall',
      createdAt: 1701115465920,
      error: {
        code: 0,
        error: '',
      },
      data: {
        data: [
          {
            _id: '672f90326e3bc679461b964b',
            company: {
              _id: '66aa55d1e6b06c44f492b7c6',
              name: 'Yelin Lapidot',
              hebrew: 'ילין לפידות',
              email: 'ylapidot@fintek.co.il',
              phone: '0545653650',
            },
            product: {
              _id: '66b1f5967fa7f8b8b8a83141',
              name: 'Health',
              hebrew: 'בריאות',
            },
            agencyFeeMonthlyDeposit: '9',
            agencyFeeMonthlyAccumulation: '1',
            agencyFeeAnnualDeposit: '2',
            agencyFeeAnnualAccumulation: '1',
            createdAt: '2024-11-09T16:39:14.544Z',
            updatedAt: '2024-11-10T05:11:03.308Z',
            __v: 0,
          },
          {
            _id: '6730465173687f87aa61253b',
            company: {
              _id: '66045d75e376171abbb826a5',
              name: 'Meitav',
              hebrew: 'מיטב',
              email: 'meitav@fintek.co.il',
              phone: '0545653650',
            },
            product: {
              _id: '6617d5db37d01fce576453cc',
              name: 'Provident',
              hebrew: 'גמל',
            },
            agencyFeeMonthlyDeposit: '0',
            agencyFeeMonthlyAccumulation: '0',
            agencyFeeAnnualDeposit: '0',
            agencyFeeAnnualAccumulation: '0',
            createdAt: '2024-11-10T05:36:17.684Z',
            updatedAt: '2024-11-10T05:36:17.684Z',
            __v: 0,
          },
        ],
        count: 2,
      },
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1720349117033,
      error: {
        collection: 'companies',
        code: 501,
        error: 'No documnets were found',
        message: 'אירעה שגיאה',
      },
      object: 'getall',
      success: false,
    },
    status: 500,
  },
};

const RESULT_GET_ONE_BY_ID = {
  default: {
    response: {
      createdAt: 1721223862251,
      error: {
        code: 0,
        error: '',
      },
      object: 'getonebyid',
      success: true,
      data: {
        company: {
          _id: '66aa55d1e6b06c44f492b7c6',
          name: 'Yelin Lapidot',
          hebrew: 'ילין לפידות',
          email: 'ylapidot@fintek.co.il',
          phone: '0545653650',
        },
        product: {
          _id: '66b1f5967fa7f8b8b8a83141',
          name: 'Health',
          hebrew: 'בריאות',
        },
        agencyFeeMonthlyDeposit: '9',
        agencyFeeMonthlyAccumulation: '1',
        agencyFeeAnnualDeposit: '2',
        agencyFeeAnnualAccumulation: '1',
        createdAt: '2024-11-09T16:39:14.544Z',
        updatedAt: '2024-11-10T05:11:03.308Z',
        __v: 0,
      },
      status: 200,
    },
  },
  error: {
    response: {
      createdAt: 1701115461239,
      object: 'getonebyid',
      success: false,
    },
    status: 500,
  },
};

const RESULT_CREATE_ONE = {
  default: {
    response: {
      createdAt: 1723012348344,
      error: {
        code: 0,
        error: '',
      },
      object: 'createone',
      success: true,
      data: {
        company: '65fc141fc676340ed68694f6',
        product: '666fe3838b5154bf71df8533',
        agencyFeeMonthlyDeposit: '0',
        agencyFeeMonthlyAccumulation: '0',
        agencyFeeAnnualDeposit: '0',
        agencyFeeAnnualAccumulation: '0',
        _id: '6734bd958b87c7b0bb5ad669',
        createdAt: '2024-11-13T14:54:13.741Z',
        updatedAt: '2024-11-13T14:54:13.741Z',
        __v: 0,
      },
    },
  },
  error: {
    response: {
      createdAt: 1701115461239,
      object: 'createone',
      success: false,
    },
    status: 500,
  },
};

const RESULT_UPDATE_ONE = {
  default: {
    response: {
      createdAt: 1721222487956,
      error: {
        code: 0,
        error: '',
      },
      object: 'updateone',
      success: true,
      data: {},
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'updateone',
      success: false,
    },
    status: 500,
  },
};

const RESULT_DELETE_MANY = {
  default: {
    response: {
      createdAt: 1732126737282,
      error: {
        code: 0,
        error: '',
      },
      object: 'deletemany',
      success: true,
      data: {
        deletedCount: 1,
      },
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'deletemany',
      success: false,
    },
    status: 500,
  },
};

const getAllAgencyCommissionsHandler = async () => {
  const { response, status } = getResponseData(RESULT_GET_ALL);
  await delay(1000);
  // this response needs to return all users - to create this mock - ask yariv which details we need
  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const updateAgencyCommissiosnHandler = async () => {
  const { response, status } = getResponseData(RESULT_UPDATE_ONE);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getOneAgencyCommissionsByIdHandler = async () => {
  const { response, status } = getResponseData(RESULT_GET_ONE_BY_ID);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const createAgencyCommissionsHelper = async () => {
  const { response, status } = getResponseData(RESULT_CREATE_ONE);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const deleteAgencyCommissionsHelper = async () => {
  const { response, status } = getResponseData(RESULT_DELETE_MANY);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export {
  updateAgencyCommissiosnHandler,
  getOneAgencyCommissionsByIdHandler,
  createAgencyCommissionsHelper,
  getAllAgencyCommissionsHandler,
  deleteAgencyCommissionsHelper,
};
