import PropTypes from 'prop-types';
import { isNumber } from '@/helpers/is';
import { ACCORD_TYPE } from '@/helpers/enums';
import { formatDateToString, isDateAfterNow } from '@/helpers/string';
import prepareTranslate from '@/helpers/dictionary';
import {
  Avatar,
  Badge,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import {
  IconAssembly,
  IconCalendar,
  IconContract,
  IconHash,
} from '@tabler/icons-react';
import styles from './style.module.css';

const AgentAgreements = (props) => {
  const {
    data = [],
  } = props;

  const t = prepareTranslate();

  const items = data.map((agreement) => {
    const {
      id,
      agentNumber,
      companyId,
      branchId,
      from,
      logo,
      till,
      agentFeeMonthlyAccumulation,
      agentFeeMonthlyDeposit,
      agentFeeAnnualAccumulation,
      agentFeeAnnualDeposit,
      accordType,
    } = agreement;

    const returnStringInsteadOfNull = (value) => {
      if (isNumber(value)) {
        return `${value}%`;
      }
      return t('components.viewAgentAgreements.labels.commission.noData');
    };

    const handleBranchString = () => {
      const branchNames = branchId.map((branch) => branch.hebrew);
      return branchNames.join(', ');
    };

    const badge = (till && !isDateAfterNow(till))
      ? <Badge variant="light" color="red">{t('global.valid.expire')}</Badge>
      : <Badge variant="light" color="blue">{t('global.valid.inspire')}</Badge>;

    const hebrewAccordType = ACCORD_TYPE.find((item) => item.value === accordType).label;

    return (
      <Paper key={id} withBorder p='md'>
        <Group justify='space-between'>
          <div>
            <Group gap='xs'>
              <Avatar size={48} src={logo} radius={10} />
              <Text fw={700}>
                {companyId?.hebrew}
              </Text>
            </Group>
          </div>

          {badge}
        </Group>

        <Grid mt={'md'}>
          {agentNumber && (
            <Grid.Col span={4}>
              <Badge
                variant='transparent'
                color='dark'
                size='md'
                leftSection = {
                  <IconHash
                    size={20}
                  />
                }
              >
                {t('components.viewAgentAgreements.labels.agentnumber')} {agentNumber}
              </Badge>
            </Grid.Col>
          )}

          {from && (
            <Grid.Col span={4}>
              <Badge
                variant='transparent'
                color='dark'
                size='md'
                leftSection = {
                  <IconCalendar
                    size={20}
                  />
                }
              >
                {t('components.viewAgentAgreements.labels.date.start')} {formatDateToString(from)}
              </Badge>
            </Grid.Col>
          )}

          <Grid.Col span={4}>
            {till && (
              <Badge
                variant='transparent'
                color='dark'
                size='md'
                leftSection = {
                  <IconCalendar
                    size={20}
                  />
                }
              >
                {t('components.viewAgentAgreements.labels.date.end')} {formatDateToString(till)}
              </Badge>
            )}
          </Grid.Col>

          <Grid.Col span={4}>
            <Badge
              variant='transparent'
              color='dark'
              size='md'
              leftSection = {
                <IconAssembly
                  size={20}
                />
              }
            >
              {t('components.viewAgentAgreements.labels.branch')}: {handleBranchString(branchId)}
            </Badge>
          </Grid.Col>

          <Grid.Col span={4}>
            <Badge
              variant='transparent'
              color='dark'
              size='md'
              leftSection = {
                <IconContract
                  size={20}
                />
              }
            >
              {t('components.viewAgentAgreements.labels.accordType')}: {hebrewAccordType}
            </Badge>
          </Grid.Col>

        </Grid>
        <div className={styles.commissions}>
          {
            accordType === ACCORD_TYPE[0].value && (
              <>
                <div className={styles.commission}>
                  <Text size='lg'>
                    {t('components.viewAgentAgreements.labels.commission.agentFeeMonthlyAccumulation')}
                  </Text>
                  <Text size='md' fw={700}>
                    {returnStringInsteadOfNull(agentFeeMonthlyAccumulation)}
                  </Text>
                </div>
                <div className={styles.commission}>
                  <Text size='lg'>
                    {t('components.viewAgentAgreements.labels.commission.agentFeeMonthlyDeposit')}
                  </Text>
                  <Text size='md' fw={700}>
                    {returnStringInsteadOfNull(agentFeeMonthlyDeposit)}
                  </Text>
                </div>
                <div className={styles.commission}>
                  <Text size='lg'>
                    {t('components.viewAgentAgreements.labels.commission.agentFeeAnnualAccumulation')}
                  </Text>
                  <Text size='md' fw={700}>
                    {returnStringInsteadOfNull(agentFeeAnnualAccumulation)}
                  </Text>
                </div>
                <div className={styles.commission}>
                  <Text size='lg'>
                    {t('components.viewAgentAgreements.labels.commission.agentFeeAnnualDeposit')}
                  </Text>
                  <Text size='md' fw={700}>
                    {returnStringInsteadOfNull(agentFeeAnnualDeposit)}
                  </Text>
                </div>
              </>
            )
          }
          {
            accordType === ACCORD_TYPE[1].value && (
              <div className={styles.commission}>
                <Text size='lg'>
                  {t('components.viewAgentAgreements.labels.commission.agentFee')}
                </Text>
                <Text size='md' fw={700}>
                  {returnStringInsteadOfNull(agentFeeMonthlyAccumulation)}
                </Text>
              </div>
            )
          }
        </div>
      </Paper>
    );
  });
  return (
    <Stack>
      {items.length ? (
        items
      ) : (
        <Text fw='bold'>{t('components.rows.empty.title')}</Text>
      )}
    </Stack>
  );
};

AgentAgreements.propTypes = {
  data: PropTypes.array,
};

export default AgentAgreements;
