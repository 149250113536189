import PropTypes from 'prop-types';
import { Dropzone } from '@mantine/dropzone';
import {
  Center,
  Flex,
  Stack,
  Text,
} from '@mantine/core';
import prepareTranslate from '@/helpers/dictionary';
import { notifications } from '@mantine/notifications';
import { CUSTOM_MIME_TYPES } from '@/helpers/enums';
import clsx from 'clsx';
import { IconX } from '@tabler/icons-react';
import styles from './style.module.css';
import vars from '../../config/vars';

const CustomDropZone = (props) => {
  const {
    handleAccept = () => {},
    disabled = false,
    outsideAccept = null,
  } = props;

  const t = prepareTranslate();
  const { maxFileSize } = vars;

  const acceptObjectMimeTypes = {};
  const maxFileSizeInBytes = maxFileSize * 1024 ** 2;

  const dropZoneClass = clsx(disabled && styles.drop_zone);
  // eslint-disable-next-line max-len
  const acceptArray = outsideAccept || Object.entries(CUSTOM_MIME_TYPES).map(([, mimeType]) => mimeType);

  // eslint-disable-next-line max-len
  acceptArray.forEach(({ mimeType, extensions }) => { acceptObjectMimeTypes[mimeType] = extensions; });
  const acceptArrayExtensions = acceptArray.flatMap(({ extensions }) => extensions).join(', ');

  const handleOnReject = (files) => {
    const overSizeFileNames = files.filter((item) => item.errors.some(({ code: errorCode }) => errorCode.includes('large'))).map(({ file: { name: fileName } }) => fileName).join(', ');
    const invalidFormatFileNames = files.filter((item) => item.errors.some(({ code: errorCode }) => errorCode.includes('type'))).map(({ file: { name: fileName } }) => fileName).join(', ');

    if (overSizeFileNames.length !== 0) {
      notifications.show({
        title: t('components.customDropZone.notification.invalidFileSize.title'),
        message: `${t('components.customDropZone.notification.invalidFileSize.message')} ${overSizeFileNames}`,
        autoClose: false,
      });
    }

    if (invalidFormatFileNames.length !== 0) {
      const InvalidFormatFileMessage = () => (
        <Stack>
          <Text c='black' size='14px'>{t('components.customDropZone.notification.invalidFileFormat.message')} {invalidFormatFileNames}</Text>
          <Text c='black' size='14px'>{t('components.customDropZone.notification.invalidFileFormat.allow')} {acceptArrayExtensions}</Text>
        </Stack>
      );

      notifications.show({
        title: t('components.customDropZone.notification.invalidFileFormat.title'),
        message: <InvalidFormatFileMessage />,
        autoClose: false,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
        <div className={styles.dropzone}>
          <Dropzone
            disabled={disabled}
            loading={false}
            onDrop={handleAccept}
            onReject={(files) => handleOnReject(files)}
            maxSize={maxFileSizeInBytes}
            className={dropZoneClass}
            accept={{ ...acceptObjectMimeTypes }}
          >
            <Dropzone.Reject>
              <Center>
                <IconX
                  size={20}
                  stroke={1.5}
                />
                <Text>
                  {t('components.customDropZone.reject.inValidFileFormat')}
                </Text>
              </Center>
            </Dropzone.Reject>
            <Flex
              direction='column'
              align='center'
              gap='sm'
            >
              <Text size="lg" inline>{t('components.files.title')}</Text>
              <Text size='sm' c='dimmed' inline mt={7}>{`${t('components.files.subtitle')} ${maxFileSize} מגה בייט`}</Text>
            </Flex>
          </Dropzone>
        </div>
    </div>
  );
};

CustomDropZone.propTypes = {
  type: PropTypes.oneOf(['agent', 'agency', 'user']),
  id: PropTypes.string,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  handleDelete: PropTypes.func,
  handleAccept: PropTypes.func,
  handleDownload: PropTypes.func,
  showForm: PropTypes.bool,
  showActions: PropTypes.bool,
  disabled: PropTypes.bool,
  outsideAccept: PropTypes.array,
};

export default CustomDropZone;
