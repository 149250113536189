import PropTypes from 'prop-types';
import prepareTranslate from '@/helpers/dictionary';
import PageTemplate from '../PageTemplate';
import Section from '../Section';
import Files from '../Files';

const EditDocuments = (props) => {
  const {
    files,
    setFiles,
  } = props;

  const t = prepareTranslate();

  const handleAccept = (uploadFiles) => {  // eslint-disable-line
    setFiles([...files, ...uploadFiles]);
  };

  return (
    <PageTemplate.Panel id='documents'>
    <Section title={t('pages.agentEdit.headers.documents')}>
      <Files
        files={files}
        showActions={false}
        handleAccept={handleAccept}
      />
    </Section>
  </PageTemplate.Panel>
  );
};

EditDocuments.propTypes = {
  form: PropTypes.object,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  open: PropTypes.func,
  close: PropTypes.func,
};

export default EditDocuments;
