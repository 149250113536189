import vars from '@/config/vars';

const {
  baseURL,
  timeout,
} = vars.request;

const axiosConfig = {
  baseURL,
  timeout,
  withCredentials: true,
};

export const headers = { 'Content-Type': 'application/json' };
export default axiosConfig;
