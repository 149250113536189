import PropTypes from 'prop-types';
import prepareTranslate from '@/helpers/dictionary';
import companyQuery from '@/api/queries/company';
import { useState } from 'react';
import { ACCORD_TYPE } from '@/helpers/enums';
import {
  Button,
  Grid,
  NumberInput,
  Select,
  TextInput,
} from '@mantine/core';
import PageTemplate from '../PageTemplate';
import Section from '../Section';
import CustomCollapse from '../CustomCollapse';

const EditClients = (props) => {
  const {
    form,
  } = props;

  const t = prepareTranslate();

  const [openedCollapse, setOpenedCollapse] = useState(-1);

  const { data: companies } = companyQuery.useGetAll();

  const companiesOptions = companies?.map((company) => ({
    label: company.hebrew,
    value: company._id,
  }));

  const handleCollapseManager = (index) => {
    let setNumber = index;

    if (openedCollapse === index) {
      setNumber = -1;
    }

    setOpenedCollapse(setNumber);
  };

  const handleDeleteCollapseManager = (index) => {
    form.removeListItem('clients', index);
  };

  return (
    <PageTemplate.Panel id='clients'>
    <Section title={t('components.editClients.header')}>
      {form.getValues().clients?.map((item, index) => {
        const agreementAccordType = form.getValues().clients[index].accordType;

        return (
          <CustomCollapse
            title={`מספר תיק ${form.getValues().clients[index].clientNumber}`}
            onDelete={() => handleDeleteCollapseManager(index)}
            onClick={() => handleCollapseManager(index)}
            open={index === openedCollapse}
            key={index + 1}
          >
            <Grid gutter={{ base: 'xl' }} key={index + 1}>
              <Grid.Col span={{ base: 4 }}>
                <Select
                  label={t('components.editClients.company')}
                  key={form.key(`clients.${index}.companyId`)}
                  data={companiesOptions}
                  {...form.getInputProps(`clients.${index}.companyId`)}
                />
                <TextInput
                  autoComplete='off'
                  maxLength={25}
                  label={t('components.editClients.hp')}
                  key={form.key(`clients.${index}.hp`)}
                  {...form.getInputProps(`clients.${index}.hp`)}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 4 }}>
                <Select
                  required
                  label={t('components.editClients.accordType')}
                  key={form.key(`clients.${index}.accordType`)}
                  defaultValue={ACCORD_TYPE[1].value}
                  data={ACCORD_TYPE}
                  {...form.getInputProps(`clients.${index}.accordType`)}
                />
                <TextInput
                  autoComplete='off'
                  label={t('components.editClients.number')}
                  key={form.key(`clients.${index}.clientNumber`)}
                  maxLength={7}
                  required
                  {...form.getInputProps(`clients.${index}.clientNumber`)}
                />
              </Grid.Col>
              <Grid.Col span={{ base: 4 }}>
                {
                  agreementAccordType === 'independent' ? (
                  <NumberInput
                    autoComplete='off'
                    label={t('components.editClients.commission')}
                    key={form.key(`clients.${index}.agentCommission`)}
                    maxLength={7}
                    required
                    defaultValue={0}
                    {...form.getInputProps(`clients.${index}.agentCommission`)}
                  />
                  ) : (
                  <NumberInput
                    autoComplete='off'
                    label={t('components.editClients.commission')}
                    key={form.key(`clients.${index}.employeeAgentCommission`)}
                    maxLength={7}
                    required
                    defaultValue={0}
                    {...form.getInputProps(`clients.${index}.employeeAgentCommission`)}
                  />
                  )
                }
              </Grid.Col>
            </Grid>
          </CustomCollapse>
        );
      })}
      <Button
        mt={15}
        mb={15}
        onClick={() => {
          form.insertListItem('clients', {
            clientNumber: '',
            accordType: ACCORD_TYPE[0].value,
          });
        }}
      >
        {t('components.editClients.addNewClient')}
      </Button>
    </Section>
  </PageTemplate.Panel>
  );
};

EditClients.propTypes = {
  form: PropTypes.object,
};

export default EditClients;
