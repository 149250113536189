import prepareTranslate from '@/helpers/dictionary';
import PropTypes from 'prop-types';
import { FAMILY_STATUS, GENDER } from '@/helpers/enums';
import { formatDDMMYYYYToString } from '@/helpers/string';
import PageTemplate from '../PageTemplate';
import SectionView from '../SectionView';

function ViewAgentDetails(props) {
  const {
    agent,
  } = props;

  const t = prepareTranslate();

  // TODO: move to agentview functions
  const detailsLines = [
    {
      title: t('components.viewAgentDetails.fullname'),
      value: agent.personalDetails.fullName,
    },
    {
      title: t('components.viewAgentDetails.uid'),
      value: agent.personalDetails.uid,
    },
    {
      title: t('components.viewAgentDetails.gender'),
      value: GENDER.find(({ value: genderValue }) => genderValue === agent.personalDetails.gender)?.label || '',
    },
    {
      title: t('components.viewAgentDetails.dob'),
      value: agent.personalDetails.dob || null,
    },
  ];

  // TODO: move to agentview functions
  const familyLines = [
    {
      title: t('components.viewAgentDetails.familyStatus'),
      value: FAMILY_STATUS.find(({ value: familtStatusValue }) => familtStatusValue === agent.personalDetails.familyStatus)?.label || '',
    },
    {
      title: t('components.viewAgentDetails.numberOfChildren'),
      value: agent.personalDetails.children?.length || '',
    },
    {
      title: t('components.viewAgentDetails.spouse'),
      value: agent.personalDetails.spouse,
    },
  ];

  // TODO: move to agentview functions
  const contactLines = [
    {
      title: t('components.viewAgentDetails.email'),
      value: agent.personalDetails.email,
    },
    {
      title: t('components.viewAgentDetails.cellphone'),
      value: agent.personalDetails.phone,
    },
    {
      title: t('components.viewAgentDetails.extracellphone'),
      value: agent.personalDetails.extraPhone,
    },
    {
      title: t('components.viewAgentDetails.website'),
      value: agent.personalDetails.website,
    },
  ];
  // TODO: move to agentview functions
  const homeLines = [
    {
      title: t('components.viewAgentDetails.homeaddress'),
      value: agent.personalDetails.homeFullAddress,
    },
    {
      title: t('components.viewAgentDetails.homephone'),
      value: agent.personalDetails.homePhone,
    },
  ];

  // TODO: move to agentview functions
  const officeLines = [
    {
      title: t('components.viewAgentDetails.officeaddress'),
      value: agent.personalDetails.officeFullAddress,
    },
    {
      title: t('components.viewAgentDetails.officephone'),
      value: agent.personalDetails.officePhone,
    },
  ];

  // TODO: move to agentview functions
  const datesLines = [
    {
      title: t('components.viewAgentDetails.policyend'),
      value: (
        <span>{formatDDMMYYYYToString(agent.personalDetails.policyEndDate)}</span>
      ),
    },
    {
      title: t('components.viewAgentDetails.tax'),
      value: (
          <span>
          {formatDDMMYYYYToString(agent.personalDetails.taxDeductionsDate)}
          </span>
      ),
    },
  ];
  return (
    <PageTemplate.Panel id='details'>
      <SectionView
        title={t('components.viewAgentDetails.headers.private')}
        lines={detailsLines}
      />
      <SectionView
        title={t('components.viewAgentDetails.headers.family')}
        lines={familyLines}
      />
      <SectionView
        title={t('components.viewAgentDetails.headers.contact')}
        lines={contactLines}
      />
      <SectionView
        title={t('components.viewAgentDetails.headers.home')}
        lines={homeLines}
      />
      <SectionView
        title={t('components.viewAgentDetails.headers.office')}
        lines={officeLines}
      />
      <SectionView
        title={t('components.viewAgentDetails.headers.dates')}
        lines={datesLines}
      />
    </PageTemplate.Panel>
  );
}

ViewAgentDetails.propTypes = {
  agent: PropTypes.object,
};

export default ViewAgentDetails;
