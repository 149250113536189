import prepareTranslate from '@/helpers/dictionary';
import { Table } from '@mantine/core';
import { ACCORD_TYPE } from '@/helpers/enums';
import PropTypes from 'prop-types';
import PageTemplate from '../PageTemplate';
import Section from '../Section';

function ViewAgentClients(props) {
  const {
    agent,
  } = props;

  const t = prepareTranslate();

  return (
    <PageTemplate.Panel id='clients'>
      <Section title={t('components.viewAgentClients.headers.clients')}>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{t('components.viewAgentClients.hp')}</Table.Th>
              <Table.Th>{t('components.viewAgentClients.company')}</Table.Th>
              <Table.Th>{t('components.viewAgentClients.clientNumber')}</Table.Th>
              <Table.Th>{t('components.viewAgentClients.accordType')}</Table.Th>
              <Table.Th>{t('components.viewAgentClients.agentCommission')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {agent.clients?.map((item, index) => {
              const accordType = ACCORD_TYPE
                .find((currentAccordType) => currentAccordType.value === item.accordType)
                .label;

              return (
                <Table.Tr key={index + 1}>
                  <Table.Td key={index + 2}>{item.hp}</Table.Td>
                  <Table.Td key={index + 3}>{item.companyId.hebrew}</Table.Td>
                  <Table.Td key={index + 4}>{item.clientNumber}</Table.Td>
                  <Table.Td key={index + 5}>{accordType}</Table.Td>
                  {
                    item.accordType === ACCORD_TYPE[0].value ? (
                      <Table.Td key={index + 6}>{`${item.agentCommission}%`}</Table.Td>
                    ) : (
                      <Table.Td key={index + 6}>{`${item.employeeAgentCommission}%`}</Table.Td>
                    )
                  }
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      </Section>
    </PageTemplate.Panel>
  );
}

ViewAgentClients.propTypes = {
  agent: PropTypes.object,
};

export default ViewAgentClients;
