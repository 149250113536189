export default {
  notification: {
    success: {
      title: 'הפרטים עודכנו בהצלחה',
      message: 'פרטי הסוכן עודכנו במערכת',
    },
    failure: {
      title: 'הפרטים לא עודכנו',
      message: 'אירעה שגיאה בניסיון העדכון',
    },
    validtion: {
      title: 'הפרטים לא עודכנו',
      message: 'אחד מהפרטים שהוזנו איזו תקין',
    },
    files: {
      title: 'הקבצים לא נשמרו',
      message: 'אחד מהקבצים שהוזנו אינו תקין',
    },
    download: {
      title: '',
      message: 'הקובץ נמצא בתהליך הורדה',
    },
    downloadFaild: {
      title: '',
      message: 'הקובץ נכשל בהורדה',
    },
  },
  headers: {
    documents: 'מסמכים',
  },
};
