export default {
  title: 'רשימת סוכנים',
  action: 'הוסף סוכן',
  fileTitle: 'סוכנים',
  notification: {
    errors: {
      find: {
        title: 'קיימת בעיה בהצגת הנתונים',
        message: 'לא ניתן למצוא סוכנים אנא נסה שנית',
      },
      delete: {
        title: 'שגיאה',
        message: 'לא ניתן למחוק סוכנים אנא נסה שנית',
      },
      export: {
        title: 'שגיאה',
        message: 'לא ניתן לייצא דו"ח',
      },
    },
  },
};
