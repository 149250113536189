export default {
  header: 'ניהול תיקים',
  clients: 'מספרי תיק',
  number: 'מספר תיק',
  addNewClient: 'הוספת תיק',
  accordType: 'סוג תיק',
  hp: 'ח.פ',
  company: 'חברה',
  commission: 'עמלת סוכן',
};
