import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const RESULT_CREATE_AGENT = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'createAgent',
      success: true,
      id: '6694a396e58fb8d498fbeace',
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'createAgent',
      success: false,
    },
    status: 500,
  },
};

const RESULT_UPDATE_AGENT = {
  default: {
    response: {
      createdAt: 1721223862251,
      error: {
        code: 0,
        error: '',
      },
      object: 'update',
      success: true,
      data: {},
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'update',
      success: false,
    },
    status: 500,
  },
};

const RESULT_GET_ONE = {
  default: {
    response: {
      createdAt: 1723012348344,
      error: {
        code: 0,
        error: '',
      },
      object: 'agent',
      success: true,
      data: {
        personalDetails: {
          uid: '00018',
          firstName: 'ישראל',
          lastName: 'ישראלי',
          fullName: 'ישראל ישראלי',
          dob: '01/01/1999',
          gender: 'male',
          phone: '0546303030',
          email: 'aaa@aaa.com',
          homeCity: 'חולון',
          homeStreet: 'יפו',
          homeHouseNumber: '4',
          officeCity: 'תל אביב',
          officeStreet: 'יפת',
          officeHouseNumber: '4',
          policyEndDate: '2024-07-30T21:00:00.000Z',
          licenseEndDate: '2024-07-29T21:00:00.000Z',
          taxDeductionsDate: '2024-07-30T21:00:00.000Z',
          professionalPolicy: '2024-07-30T21:00:00.000Z',
          homeFullAddress: 'עכגעכע 4 גככג ',
          officeFullAddress: 'עכרררק 4 עכגעכעכג',
        },
        agreements: [
          {
            companyId: '6609110becb461e59d78a4b5',
            branchId: '6617d5b437d01fce576453cb',
            agentNumber: '453454',
            from: '2024-03-31T21:00:00.000Z',
            till: '2025-01-31T22:00:00.000Z',
            datesArray: [
              {
                year: '2024',
                month: '4',
              },
              {
                year: '2024',
                month: '5',
              },
              {
                year: '2024',
                month: '6',
              },
              {
                year: '2024',
                month: '7',
              },
              {
                year: '2024',
                month: '8',
              },
              {
                year: '2024',
                month: '9',
              },
              {
                year: '2024',
                month: '10',
              },
              {
                year: '2024',
                month: '11',
              },
              {
                year: '2024',
                month: '12',
              },
              {
                year: '2025',
                month: '1',
              },
              {
                year: '2025',
                month: '2',
              },
            ],
            agreementCommisionScopeForAgency: 1.1,
            agreementCommisionPaidoffForAgency: 2,
            agreementCommisionScopeForAgent: 3,
            agreementCommisionPaidoffForAgent: 4,
            _id: '66a1fdc307ae448b6490664b',
            createdAt: '2024-07-28T12:14:14.702Z',
            updatedAt: '2024-07-28T12:14:14.702Z',
            id: '66a1fdc307ae448b6490664b',
          },
        ],
        clients: [],
        status: 'active',
        agentType: 'CTO',
        employeeType: 'employee',
        createdAt: '2024-07-25T07:24:51.550Z',
        updatedAt: '2024-07-28T12:14:14.702Z',
        __v: 0,
        agentNumber: [
          '453454',
        ],
        id: null,
      },
    },
  },
  error: {
    response: {
      createdAt: 1701115461239,
      object: 'getAll',
      success: false,
    },
    status: 500,
  },
};

const RESULT_GET_ALL = {
  default: {
    response: {
      createdAt: 1721222487956,
      error: {
        code: 0,
        error: '',
      },
      object: 'agents',
      success: true,
      data: {
        data: [
          {
            _id: '66817d8e2537f3839fa7dd07',
            personalDetails: {
              uid: '00018',
              firstName: 'לא',
              lastName: 'למחוק',
              dob: '2024-06-01T21:00:00.000Z',
              gender: 'female',
              phone: '0546303000',
              email: 'ewtgf@ggggg.com',
              homeCity: 'רמת גן',
              homeStreet: 'בלה',
              homeHouseNumber: '5',
              homeZipCode: '5454545',
              homePhone: '0546303000',
              officeCity: 'יפו',
              officeStreet: 'יפת',
              officeHouseNumber: '4',
              officeZipCode: '4545454',
              officePhone: '0566606006',
              familyStatus: 'single',
              policyEndDate: '2024-06-10T21:00:00.000Z',
              licenseEndDate: '2024-06-08T21:00:00.000Z',
              taxDeductionsDate: '2024-06-18T21:00:00.000Z',
              homeFullAddress: 'בלה 5 רמת גן, 5454545',
              officeFullAddress: 'יפת 4 יפו, 4545454',
              fullName: 'לא למחוק',
            },
            agreements: [
              {
                datesArray: [
                  {
                    year: '2024',
                    month: '2',
                  },
                  {
                    year: '2024',
                    month: '3',
                  },
                  {
                    year: '2024',
                    month: '4',
                  },
                ],
                companyId: '66045d75e376171abbb826a5',
                branchId: '6617d5b437d01fce576453cb',
                agreementNumber: '753698',
                agentNumber: '1645',
                from: '2024-01-31T22:00:00.000Z',
                till: '2024-03-31T21:00:00.000Z',
                agreementCommisionscope: 4,
                agreementCommisionpaidoff: 2,
                createdAt: '2024-07-16T13:30:23.816Z',
                updatedAt: '2024-07-16T13:30:23.816Z',
              },
            ],
            status: 'active',
            createdAt: '2024-06-30T09:04:14.413Z',
            updatedAt: '2024-06-30T09:04:14.413Z',
            __v: 0,
            agentType: 'CTO',
            clients: [],
            agentNumber: [
              '1645',
            ],
            id: '66817d8e2537f3839fa7dd07',
          },
          {
            _id: '66817e312537f3839fa7dd08',
            personalDetails: {
              uid: '00018',
              firstName: 'למחוק',
              lastName: 'לא',
              dob: '2024-06-01T21:00:00.000Z',
              gender: 'female',
              phone: '0546303000',
              email: 'ewtgf@ggggg.com',
              homeCity: 'רמת גן',
              homeStreet: 'בלה',
              homeHouseNumber: '5',
              homeZipCode: '5454545',
              homePhone: '0546303000',
              officeCity: 'יפו',
              officeStreet: 'יפת',
              officeHouseNumber: '4',
              officeZipCode: '4545454',
              officePhone: '0566606006',
              familyStatus: 'single',
              policyEndDate: '2024-06-10T21:00:00.000Z',
              licenseEndDate: '2024-06-08T21:00:00.000Z',
              taxDeductionsDate: '2024-06-18T21:00:00.000Z',
              homeFullAddress: 'בלה 5 רמת גן, 5454545',
              officeFullAddress: 'יפת 4 יפו, 4545454',
              fullName: 'למחוק לא',
            },
            agreements: [
              {
                datesArray: [
                  {
                    year: '2024',
                    month: '2',
                  },
                  {
                    year: '2024',
                    month: '3',
                  },
                  {
                    year: '2024',
                    month: '4',
                  },
                ],
                companyId: '66045d75e376171abbb826a5',
                branchId: '6617d5b437d01fce576453cb',
                agreementNumber: '753698',
                agentNumber: '2-2093',
                from: '2024-01-31T22:00:00.000Z',
                till: '2024-03-31T21:00:00.000Z',
                agreementCommisionscope: 1,
                agreementCommisionpaidoff: 3,
                createdAt: '2024-07-16T13:30:23.816Z',
                updatedAt: '2024-07-16T13:30:23.816Z',
              },
            ],
            status: 'active',
            createdAt: '2024-06-30T09:04:14.413Z',
            updatedAt: '2024-06-30T09:04:14.413Z',
            __v: 0,
            agentType: 'CTO',
            clients: [],
            agentNumber: [
              '2-2093',
            ],
            id: '66817e312537f3839fa7dd08',
          },
          {
            _id: '66828b7b2537f3839fa7dd0b',
            personalDetails: {
              uid: '00018',
              firstName: 'אורן',
              lastName: 'לא למחוק',
              dob: '2024-06-01T21:00:00.000Z',
              gender: 'female',
              phone: '0546303000',
              email: 'ewtgf@ggggg.com',
              homeCity: 'רמת גן',
              homeStreet: 'בלה',
              homeHouseNumber: '5',
              homeZipCode: '5454545',
              homePhone: '0546303000',
              officeCity: 'יפו',
              officeStreet: 'יפת',
              officeHouseNumber: '4',
              officeZipCode: '4545454',
              officePhone: '0566606006',
              familyStatus: 'single',
              policyEndDate: '2024-06-10T21:00:00.000Z',
              licenseEndDate: '2024-06-08T21:00:00.000Z',
              taxDeductionsDate: '2024-06-18T21:00:00.000Z',
              homeFullAddress: 'בלה 5 רמת גן, 5454545',
              officeFullAddress: 'יפת 4 יפו, 4545454',
              fullName: 'אורן לא למחוק',
            },
            agreements: [
              {
                datesArray: [
                  {
                    year: '2024',
                    month: '2',
                  },
                  {
                    year: '2024',
                    month: '3',
                  },
                  {
                    year: '2024',
                    month: '4',
                  },
                ],
                companyId: '66045d75e376171abbb826a5',
                branchId: '6617d5b437d01fce576453cb',
                agreementNumber: '753698',
                agentNumber: '2-2532',
                from: '2024-01-31T22:00:00.000Z',
                till: '2024-03-31T21:00:00.000Z',
                agreementCommisionscope: 2,
                agreementCommisionpaidoff: 1,
                createdAt: '2024-07-16T13:30:23.816Z',
                updatedAt: '2024-07-16T13:30:23.816Z',
              },
            ],
            status: 'active',
            createdAt: '2024-06-30T09:04:14.413Z',
            updatedAt: '2024-06-30T09:04:14.413Z',
            __v: 0,
            agentType: 'CTO',
            clients: [],
            agentNumber: [
              '2-2532',
            ],
            id: '66828b7b2537f3839fa7dd0b',
          },
          {
            _id: '66828ea5d18362821dda28d6',
            personalDetails: {
              uid: '00018',
              firstName: 'בבקשה',
              lastName: 'לא למחוק',
              dob: '2024-07-02T21:00:00.000Z',
              gender: 'male',
              phone: '0546303000',
              email: 'dfgf@gfgf.com',
              homeCity: 'עכע',
              homeStreet: 'עכע',
              homeHouseNumber: '5',
              homeZipCode: '5454545',
              homePhone: '0546303030',
              officeCity: 'עכעכע',
              officeStreet: 'עכעעככ',
              officeHouseNumber: '4',
              officeZipCode: '4545',
              officePhone: '0546303033',
              familyStatus: 'single',
              policyEndDate: '2024-07-02T21:00:00.000Z',
              licenseEndDate: '2024-07-14T21:00:00.000Z',
              taxDeductionsDate: '2024-07-24T21:00:00.000Z',
              homeFullAddress: 'עכע 5 עכע, 5454545',
              officeFullAddress: 'עכעעככ 4 עכעכע, 4545',
              fullName: 'בבקשה לא למחוק',
            },
            agreements: [
              {
                datesArray: [
                  {
                    year: '2024',
                    month: '2',
                  },
                  {
                    year: '2024',
                    month: '3',
                  },
                  {
                    year: '2024',
                    month: '4',
                  },
                ],
                companyId: '66045d75e376171abbb826a5',
                branchId: '6617d5db37d01fce576453cc',
                agreementNumber: '132565',
                agentNumber: '1644',
                from: '2024-01-31T22:00:00.000Z',
                till: '2024-03-31T21:00:00.000Z',
                agreementCommisionscope: 2,
                agreementCommisionpaidoff: 1,
                createdAt: '2024-07-16T13:30:23.816Z',
                updatedAt: '2024-07-16T13:30:23.816Z',
              },
              {
                datesArray: [
                  {
                    year: '2024',
                    month: '2',
                  },
                  {
                    year: '2024',
                    month: '3',
                  },
                  {
                    year: '2024',
                    month: '4',
                  },
                ],
                companyId: '66045d75e376171abbb826a5',
                branchId: '6617d5b437d01fce576453cb',
                agreementNumber: '132565',
                agentNumber: '1644',
                from: '2024-01-31T22:00:00.000Z',
                till: '2024-03-31T21:00:00.000Z',
                agreementCommisionscope: 2,
                agreementCommisionpaidoff: 1,
                createdAt: '2024-07-16T13:30:23.816Z',
                updatedAt: '2024-07-16T13:30:23.816Z',
              },
            ],
            status: 'active',
            createdAt: '2024-07-01T11:10:29.628Z',
            updatedAt: '2024-07-01T11:10:29.628Z',
            __v: 0,
            agentType: 'CTO',
            clients: [],
            agentNumber: [
              '1644',
              '1644',
            ],
            id: '66828ea5d18362821dda28d6',
          },
          {
            _id: '6694a396e58fb8d498fbeace',
            personalDetails: {
              uid: '00018',
              firstName: 'אורן',
              lastName: 'בדיקה',
              dob: '2024-06-30T21:00:00.000Z',
              gender: 'male',
              phone: '034545455',
              email: 'adds@fdfd.com',
              homeCity: 'חולון',
              homeStreet: 'כגכ',
              homeHouseNumber: '4',
              homeZipCode: '434343',
              homePhone: '034343434',
              officeCity: 'תל אביב',
              officeStreet: 'עכעכ',
              officeHouseNumber: '4',
              officeZipCode: '43434',
              officePhone: '035445454',
              familyStatus: 'single',
              policyEndDate: '2024-07-16T21:00:00.000Z',
              licenseEndDate: '2024-07-15T21:00:00.000Z',
              taxDeductionsDate: '2024-07-22T21:00:00.000Z',
              createdAt: '2024-07-17T05:29:59.787Z',
              updatedAt: '2024-07-17T05:29:59.787Z',
              homeFullAddress: 'כגכ 4 חולון, 434343',
              officeFullAddress: 'עכעכ 4 תל אביב, 43434',
              fullName: 'אורן בדיקה',
            },
            agreements: [
              {
                companyId: '65fc141fc676340ed68694f6',
                branchId: '6617d5b437d01fce576453cb',
                agreementNumber: '454545',
                agentNumber: '3344',
                from: '2024-01-31T22:00:00.000Z',
                till: '2024-03-31T21:00:00.000Z',
                agreementCommisionscope: 4,
                agreementCommisionpaidoff: 2,
                createdAt: '2024-07-17T05:29:59.787Z',
                updatedAt: '2024-07-17T05:29:59.787Z',
                datesArray: [
                  {
                    year: '2024',
                    month: '2',
                  },
                  {
                    year: '2024',
                    month: '3',
                  },
                  {
                    year: '2024',
                    month: '4',
                  },
                ],
              },
              {
                companyId: '66045dcfe376171abbb826a7',
                branchId: '666fe3838b5154bf71df8533',
                agreementNumber: '5454545',
                agentNumber: '44444',
                from: '2024-07-31T21:00:00.000Z',
                till: null,
                agreementCommisionscope: 3,
                agreementCommisionpaidoff: 4,
                createdAt: '2024-07-17T05:29:59.787Z',
                updatedAt: '2024-07-17T05:29:59.787Z',
                datesArray: [],
              },
            ],
            status: 'active',
            agentType: 'integratedAgent',
            createdAt: '2024-07-15T04:20:38.373Z',
            updatedAt: '2024-07-17T05:29:59.787Z',
            __v: 0,
            clients: [
              {
                clientNumber: '123',
                createdAt: '2024-07-17T05:29:59.787Z',
                updatedAt: '2024-07-17T05:29:59.787Z',
              },
            ],
            agentNumber: [
              '3344',
              '44444',
            ],
            id: '6694a396e58fb8d498fbeace',
          },
          {
            _id: '6694f09def8464c34c6cbcbb',
            personalDetails: {
              uid: '00018',
              firstName: 'עכגעכג',
              lastName: 'עכגע',
              dob: '2024-06-30T21:00:00.000Z',
              gender: 'male',
              phone: '0546300303',
              email: 'gdfgd@ggf.com',
              homeCity: 'עכע',
              homeStreet: 'עכגעכג',
              homeHouseNumber: '4',
              homeZipCode: '435435',
              homePhone: '035656565',
              officeCity: 'עכגעכג',
              officeStreet: 'עכגעכג',
              officeHouseNumber: '4',
              officeZipCode: '4354354',
              officePhone: '035656565',
              familyStatus: 'single',
              policyEndDate: '2024-07-01T21:00:00.000Z',
              licenseEndDate: '2024-07-04T21:00:00.000Z',
              taxDeductionsDate: '2024-07-21T21:00:00.000Z',
              createdAt: '2024-07-15T09:49:17.653Z',
              updatedAt: '2024-07-15T09:49:17.653Z',
              homeFullAddress: 'עכגעכג 4 עכע, 435435',
              officeFullAddress: 'עכגעכג 4 עכגעכג, 4354354',
              fullName: 'עכגעכג עכגע',
            },
            agreements: [
              {
                agreementNumber: '5465465',
                agentNumber: '5654654',
                from: '2023-12-31T22:00:00.000Z',
                till: '2024-05-31T21:00:00.000Z',
                agreementCommisionscope: 1,
                agreementCommisionpaidoff: 2,
                createdAt: '2024-07-15T09:49:17.654Z',
                updatedAt: '2024-07-15T09:49:17.654Z',
                datesArray: [
                  {
                    year: '2024',
                    month: '1',
                  },
                  {
                    year: '2024',
                    month: '2',
                  },
                  {
                    year: '2024',
                    month: '3',
                  },
                  {
                    year: '2024',
                    month: '4',
                  },
                  {
                    year: '2024',
                    month: '5',
                  },
                  {
                    year: '2024',
                    month: '6',
                  },
                ],
              },
              {
                agreementNumber: '4564565',
                agentNumber: '54654',
                from: '2023-12-31T22:00:00.000Z',
                till: '2024-08-31T21:00:00.000Z',
                agreementCommisionscope: 5,
                agreementCommisionpaidoff: 4,
                createdAt: '2024-07-15T09:49:17.654Z',
                updatedAt: '2024-07-15T09:49:17.654Z',
                datesArray: [
                  {
                    year: '2024',
                    month: '1',
                  },
                  {
                    year: '2024',
                    month: '2',
                  },
                  {
                    year: '2024',
                    month: '3',
                  },
                  {
                    year: '2024',
                    month: '4',
                  },
                  {
                    year: '2024',
                    month: '5',
                  },
                  {
                    year: '2024',
                    month: '6',
                  },
                  {
                    year: '2024',
                    month: '7',
                  },
                  {
                    year: '2024',
                    month: '8',
                  },
                  {
                    year: '2024',
                    month: '9',
                  },
                ],
              },
            ],
            status: 'active',
            agentType: 'integratedAgent',
            createdAt: '2024-07-15T09:49:17.654Z',
            updatedAt: '2024-07-15T09:49:17.654Z',
            __v: 0,
            clients: [],
            agentNumber: [
              '5654654',
              '54654',
            ],
            id: '6694f09def8464c34c6cbcbb',
          },
          {
            _id: '6694f3c4ef8464c34c6cbce6',
            personalDetails: {
              uid: '00018',
              firstName: 'עייע',
              lastName: 'עכגעכג',
              dob: '2024-06-30T21:00:00.000Z',
              gender: 'male',
              phone: '0546303030',
              email: 'gfgfd@gfgf.com',
              homeCity: 'עכגע',
              homeStreet: 'עכגעגכ',
              homeHouseNumber: '4',
              homeZipCode: '5465464',
              homePhone: '035656565',
              officeCity: 'טארט',
              officeStreet: 'טארטר',
              officeHouseNumber: '5',
              officeZipCode: '6546454',
              officePhone: '035656565',
              familyStatus: 'single',
              policyEndDate: '2024-07-14T21:00:00.000Z',
              licenseEndDate: '2024-07-17T21:00:00.000Z',
              taxDeductionsDate: '2024-07-21T21:00:00.000Z',
              createdAt: '2024-07-15T10:02:44.395Z',
              updatedAt: '2024-07-15T10:02:44.395Z',
              homeFullAddress: 'עכגעגכ 4 עכגע, 5465464',
              officeFullAddress: 'טארטר 5 טארט, 6546454',
              fullName: 'עייע עכגעכג',
            },
            agreements: [
              {
                agreementNumber: '6456456',
                agentNumber: '54546',
                from: '2023-12-31T22:00:00.000Z',
                till: '2024-05-31T21:00:00.000Z',
                agreementCommisionscope: 5,
                agreementCommisionpaidoff: 4,
                createdAt: '2024-07-15T10:02:44.395Z',
                updatedAt: '2024-07-15T10:02:44.395Z',
                datesArray: [
                  {
                    year: '2024',
                    month: '1',
                  },
                  {
                    year: '2024',
                    month: '2',
                  },
                  {
                    year: '2024',
                    month: '3',
                  },
                  {
                    year: '2024',
                    month: '4',
                  },
                  {
                    year: '2024',
                    month: '5',
                  },
                  {
                    year: '2024',
                    month: '6',
                  },
                ],
              },
            ],
            status: 'active',
            agentType: 'shareholder',
            createdAt: '2024-07-15T10:02:44.395Z',
            updatedAt: '2024-07-15T10:02:44.395Z',
            __v: 0,
            clients: [],
            agentNumber: [
              '54546',
            ],
            id: '6694f3c4ef8464c34c6cbce6',
          },
          {
            _id: '6697882092499f7a89be5937',
            personalDetails: {
              uid: '00018',
              firstName: 'איש',
              lastName: 'בדיקה',
              dob: '2024-07-01T21:00:00.000Z',
              gender: 'female',
              phone: '0546465646',
              email: 'aaaa@aaaa.com',
              homeCity: 'עכעכג',
              homeStreet: 'עכגעכג',
              homeHouseNumber: '5',
              homeZipCode: '4546655',
              homePhone: '035656565',
              officeCity: 'יפו',
              officeStreet: 'עכגעכגעכג',
              officeHouseNumber: '4',
              officeZipCode: '4554645',
              officePhone: '035656565',
              familyStatus: 'single',
              policyEndDate: '2024-06-30T21:00:00.000Z',
              licenseEndDate: '2024-07-02T21:00:00.000Z',
              taxDeductionsDate: '2024-07-10T21:00:00.000Z',
              createdAt: '2024-07-17T09:00:16.290Z',
              updatedAt: '2024-07-17T09:00:16.290Z',
              homeFullAddress: 'עכגעכג 5 עכעכג, 4546655',
              officeFullAddress: 'עכגעכגעכג 4 יפו, 4554645',
              fullName: 'איש בדיקה',
            },
            agreements: [],
            clients: [
              {
                clientNumber: '4543543',
                createdAt: '2024-07-17T09:00:16.290Z',
                updatedAt: '2024-07-17T09:00:16.290Z',
              },
            ],
            status: 'active',
            agentType: 'CTO',
            createdAt: '2024-07-17T09:00:16.290Z',
            updatedAt: '2024-07-17T09:00:16.290Z',
            __v: 0,
            agentNumber: [],
            id: '6697882092499f7a89be5937',
          },
        ],
        count: 8,
      },
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'getAll',
      success: false,
    },
    status: 500,
  },
};

const RESULT_EDIT_AGENT = {
  default: {
    response: {
      createdAt: 1701115465920,
      object: 'editAgent',
      success: true,
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1701115461239,
      object: 'editAgent',
      success: false,
    },
    status: 500,
  },
};

const createAgentHandler = async () => {
  const { response, status } = getResponseData(RESULT_CREATE_AGENT);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getAllAgentsHandler = async () => {
  const { response, status } = getResponseData(RESULT_GET_ALL);
  await delay(1000);
  // this response needs to return all users - to create this mock - ask yariv which details we need
  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const editAgentHandler = async () => {
  const { response, status } = getResponseData(RESULT_EDIT_AGENT);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getAgentHandler = async () => {
  const { response, status } = getResponseData(RESULT_GET_ONE);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const updateAgentHandler = async () => {
  const { response, status } = getResponseData(RESULT_UPDATE_AGENT);
  await delay(1000);

  return new HttpResponse(JSON.stringify(response), {
    status,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export {
  createAgentHandler,
  getAllAgentsHandler,
  editAgentHandler,
  getAgentHandler,
  updateAgentHandler,
};
