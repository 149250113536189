import Comments from '@/components/Comments';
import prepareTranslate from '@/helpers/dictionary';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageTemplate from '@/components/PageTemplate';
import agencyCommissionsMutation from '@/api/mutitations/agencyCommissions';
import { Button } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { routeWithParams } from '@/helpers/route';
import routes from '@/routes';
import { useQueryClient } from '@tanstack/react-query';

function Template(props) {
  const {
    loading,
    open,
    close,
    children,
    form,
  } = props;

  const t = prepareTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: agencyCommissionsMutate } = agencyCommissionsMutation.useUpdateOne();

  const tabs = [
    {
      title: t('pages.agencyCommissionsEdit.side.comments'),
      content: <Comments type='agencyCommissions' id={id} showForm />,
      isDefault: true,
    },
  ];

  const onError = () => {
    open();
    notifications.show({
      title: t('pages.agencyCommissionsEdit.notification.validtion.title'),
      message: t('pages.agencyCommissionsEdit.notification.validtion.message'),
    });
    close();
  };

  // eslint-disable-next-line consistent-return
  const handleAgencyCommissionUpdateClick = async (formData) => {
    try {
      open();

      const requestData = { id, ...formData };
      const editedAgencyCommissions = await agencyCommissionsMutate(requestData);

      if (!editedAgencyCommissions) {
        notifications.show({
          title: t('pages.agencyCommissionsEdit.notification.failure.title'),
          message: t('pages.agencyCommissionsEdit.notification.failure.message'),
        });
        close();
        return null;
      }

      notifications.show({
        title: t('pages.agencyCommissionsEdit.notification.success.title'),
        message: t('pages.agencyCommissionsEdit.notification.success.message'),
      });

      queryClient.invalidateQueries({ queryKey: ['agencyCommissions'] });
      navigate(routeWithParams(routes.agencyCommissionsView.path, { id }));
      close();
    } catch (error) {
      close();
    }
  };

  const handleAgencyCommissionsEditCancelClick = () => {
    navigate(routes.agencyCommissions.path);
  };

  const actions = [
    <Button
      key='primary'
      loading={loading}
      leftSection={<IconCheck />}
      onClick={form.onSubmit(handleAgencyCommissionUpdateClick, onError)}
    >
      {t('global.update')}
    </Button>,
    <Button key='cancel' variant='outline' onClick={handleAgencyCommissionsEditCancelClick}>
      {t('global.cancel')}
    </Button>,
  ];

  return (
    <PageTemplate
      tabs={tabs}
      tabsTitle={t('pages.agencyCommissionsEdit.side.tabs')}
      actionsTitle={t('pages.agencyCommissionsEdit.side.actions')}
      actions={actions}
  >
    {children}
  </PageTemplate>
  );
}

Template.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.func,
  close: PropTypes.func,
  children: PropTypes.object,
  form: PropTypes.object,
};

export default Template;
