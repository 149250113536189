export default {
  title: 'צפייה בעמלות סוכנות',
  action: 'ערוך עמלות סוכנות',
  details: {
    company: 'חברה',
    product: 'מוצר',
    agencyFeeMonthlyDeposit: 'עמלת סוכנות נפרעים מהפקדה',
    agencyFeeMonthlyAccumulation: 'עמלת סוכנות נפרעים מצבירה',
    agencyFeeAnnualAccumulation: 'עמלת סוכנות היקף מצבירה',
    agencyFeeAnnualDeposit: 'עמלת סוכנות היקף מהפקדה',
  },
  side: {
    tabs: 'מידע נוסף',
    comments: 'הערות',
    histories: 'היסטוריה',
    actions: 'פעולות',
  },
};
