import { headers as defaultHeaders } from '@/config/axios';
import axiosRequest from '.';

const getAll = async (data) => (
  axiosRequest({
    data,
    url: '/v1/agencyCommissions/getAll',
    method: 'POST',
    hders: defaultHeaders,
  })
);

const getOneById = async (data) => (
  axiosRequest({
    data,
    url: '/v1/agencyCommissions/getOneById',
    method: 'POST',
    headers: defaultHeaders,
  })
);

const updateOne = async (data) => (
  axiosRequest({
    data,
    url: '/v1/agencyCommissions/updateOne',
    method: 'POST',
    headers: defaultHeaders,
  })
);

const createOne = async (data) => (
  axiosRequest({
    data,
    url: '/v1/agencyCommissions/createOne',
    method: 'POST',
    headers: defaultHeaders,
  })

);
const deleteMany = async (data) => (
  axiosRequest({
    data,
    url: '/v1/agencyCommissions/deleteMany',
    method: 'POST',
    headers: defaultHeaders,
  })
);

export {
  getAll,
  getOneById,
  updateOne,
  createOne,
  deleteMany,
};
