export default {
  notification: {
    invalidFileSize: {
      title: 'הקבצים לא נשמרו',
      message: 'הקבצים הבאים עולים על גודל הקובץ המותר:',
    },
    invalidFileFormat: {
      title: 'הקבצים לא נשמרו',
      message: 'לא ניתן להעלות את הקבצים:',
      allow: 'סוגי הקבצים שניתן להעלות הם:',
    },
  },
  reject: {
    inValidFileFormat: 'הפורמט אינו מורשה',
  },
};
