export default {
  from: 'מתאריך',
  till: 'עד תאריך',
  number: 'מספר הסכם',
  agent: 'מספר סוכן',
  company: 'יצרן',
  branch: 'ענף',
  branches: 'ענפים',
  addAgreement: 'הוספת הסכם',
  accordType: 'סוג הסכם',
  commision: {
    agentFeeMonthlyAccumulation: 'עמלת סוכן נפרעים מהפקדה',
    agentFeeMonthlyDeposit: 'עמלת סוכן נפרעים מצבירה',
    agentFeeAnnualAccumulation: 'עמלת סוכן היקף מצבירה',
    agentFeeAnnualDeposit: 'עמלת סוכן היקף מהפקדה',
    employeeAgentCommission: 'עמלת סוכן שכיר',
  },
};
