import PageHead from '@/components/PageHead';
import docsMutation from '@/api/mutitations/docs';
import PageTemplate from '@/components/PageTemplate';
import useAccessToken from '@/hooks/useAccessToken';
import Table from '@/components/Table';
import ChartComponent from '@/components/Chart';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import agentQuery from '@/api/queries/agent';
import { isObjectEmpty } from '@/helpers/is';
import companyQuery from '@/api/queries/company';
import productQuery from '@/api/queries/product';
import exportRuleQuery from '@/api/queries/exportRule';
import { downloadFile } from '@/helpers/download';
import { notifications } from '@mantine/notifications';
import prepareTranslate from '@/helpers/dictionary';

const ReportView = () => {
  const t = prepareTranslate();
  useAccessToken();

  const [identifierId, setIdentifierId] = useState();
  const [columns, setColumns] = useState([]);
  const [noIdentifiers, setNoIdentifiers] = useState(false);

  const { id: reportId } = useParams();
  // eslint-disable-next-line max-len
  const { data: exportRuleData, isFetching: exportRuleFetching } = exportRuleQuery.useFindById({ id: reportId });
  const { data: companyData } = companyQuery.useGetAll();
  const { data: productData } = productQuery.useGetAll();
  const { mutateAsync: chartMutateAsync } = docsMutation.useExportChart();
  const { mutateAsync: tableMutateAsync } = docsMutation.useExportTable();
  const { mutateAsync: exportFileMutate } = docsMutation.useExportFile();
  // eslint-disable-next-line max-len
  const { data: agentData, isFetching: agentFetching } = agentQuery.useGetAgentById({ id: identifierId });

  // eslint-disable-next-line consistent-return
  const getChartData = async (query) => {
    let returnData;

    if (!isObjectEmpty(query)) {
      const {
        agent,
        year,
        month,
        company,
        product,
      } = query;

      const seriesFormat = { name: [] };
      let queryData;

      if (company.length !== 0) {
        seriesFormat.name.unshift('company');
      }

      if (product.length !== 0) {
        seriesFormat.name.unshift('product');
      }

      if (agent.length > 1) {
        seriesFormat.name.unshift({ name: 'personalDetails', value: ['uid', 'fullName'] });
      } else if (agent.length === 0) {
        setNoIdentifiers(true);
      } else {
        setIdentifierId(agent);
      }

      if (seriesFormat.name.length === 0) {
        seriesFormat.name.push('primaryValue');
      }

      queryData = {
        year,
        month,
        identifiers: agent,
        query: {},
        additionalData: {
          companyId: company,
          productId: product,
        },
      };

      queryData = { exportRuleId: reportId, seriesFormat, ...queryData };

      try {
        returnData = await chartMutateAsync(queryData);
      } catch {
        returnData = { data: [], series: [] };
      }

      return returnData;
    }
  };

  const getTableData = async (start, end, query) => {
    let returnData = { data: [], series: [] };
    const { agent, year, month } = query;

    const queryData = {
      year,
      month,
      identifiers: agent,
      query: {},
      additionalData: {
        companyId: query.company,
        productId: query.product,
      },
      exportRuleId: reportId,
    };

    try {
      const { data: mutateData, count } = await tableMutateAsync(queryData);
      const { rows, columns: dataColumns } = mutateData;

      setColumns(dataColumns);
      returnData = { data: rows, count };
    } catch {
      returnData = { data: [], count: 0 };
    }

    return returnData;
  };

  const handleExport = async (option, query) => {
    const { agent, year, month } = query;

    const exportData = {
      columns,
      query: {},
      fileType: option,
      service: 'docs',
      titleArray: ['personalDetails.fullName'],
      furtherData: {
        identifiers: agent,
        additionalData: {
          companyId: query.company,
          productId: query.product,
        },
        exportRuleId: reportId,
        year,
        month,
      },
    };

    const fileTitleBase = exportRuleData?.ruleName;
    const fileTitle = option === 'pdf' ? `${fileTitleBase}.pdf` : `${fileTitleBase}.xlsx`;

    try {
      const fileData = await exportFileMutate(exportData);

      downloadFile(fileData, fileTitle);
    } catch (error) {
      notifications.show({
        title: t('pages.agents.notification.errortitle'),
        message: t('pages.agents.notification.errormessage'),
      });
      // eslint-disable-next-line no-console
      console.error('Error fetching users:', error);
    }
  };

  const fullName = noIdentifiers ? 'כל הסוכנים' : agentData?.personalDetails?.fullName;
  const pageHeader = `${exportRuleData?.ruleName} - ${fullName}`;

  useEffect(() => {
    const columnsCopy = columns;
    const companySelect = companyData && companyData
      .map((company) => ({ label: company.hebrew, value: company._id }));
    const productSelect = productData && productData
      .map((product) => ({ label: product.hebrew, value: product._id }));
    const companyColumnIndex = columnsCopy.findIndex((column) => column.accessor === 'company');
    columnsCopy[companyColumnIndex] = { data: companySelect, ...columnsCopy[companyColumnIndex] };
    const productColumnIndex = columnsCopy.findIndex((column) => column.accessor === 'product');
    columnsCopy[productColumnIndex] = { data: productSelect, ...columnsCopy[productColumnIndex] };
    setColumns(columnsCopy);
  }, [companyData, productData, columns]);

  return (
    <>
      <PageHead
        title={(!exportRuleFetching && !agentFetching) && pageHeader}
      />
      <PageTemplate>
         <Table
          isExportActive
          exportOptions={['excel']}
          getData={getTableData}
          columns={columns}
          onExport={handleExport}
          isColumnsActive={false}
          childElement={ChartComponent}
          childGetData={getChartData}
          isFilterActive={false}
        />
      </PageTemplate>
    </>
  );
};

export default ReportView;
