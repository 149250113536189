import { headers as defaultHeaders } from '@/config/axios';
import axiosRequest from '.';

const getAll = async () => (
  axiosRequest({
    url: '/v1/company/getAll',
    method: 'POST',
    headers: defaultHeaders,
  })
);

const findById = async (data = {}) => (
  axiosRequest({
    data,
    url: '/v1/company/findById',
    method: 'POST',
    headers: defaultHeaders,
  })
);

export {
  getAll,
  findById,
};
