export default {
  title: 'צפייה בסוכן {1}',
  subtitle: 'עודכן לאחרונה בתאריך {1}',
  action: 'ערוך סוכן',
  menu: {
    details: 'פרטים',
    contracts: 'הסכמים {1}',
    documents: 'מסמכים {1}',
    clients: 'תיקים {1}',
  },
  status: {
    active: 'פעיל',
    archive: 'ארכיון',
  },
  side: {
    tabs: 'מידע נוסף',
    comments: 'הערות',
    histories: 'היסטוריה',
  },
  labels: {
    commision: {
      agentFeeMonthlyAccumulation: 'עמלת סוכן נפרעים מהפקדה',
      agentFeeMonthlyDeposit: 'עמלת סוכן נפרעים מצבירה',
      agentFeeAnnualAccumulation: 'עמלת סוכן היקף מצבירה',
      agentFeeAnnualDeposit: 'עמלת סוכן היקף מהפקדה',
    },
  },
  headers: {
    history: 'היסטוריה',
    comment: 'הערות',
    contracts: 'הסכמים',
    extra: 'מידע נוסף',
  },
};
