export default {
  sitename: 'פינטק',
  back: 'בחזרה אחורה',
  logout: 'התנתק',
  continue: 'המשך',
  update: 'עדכן',
  save: 'שמור',
  cancel: 'ביטול',
  approve: 'מאשר',
  close: 'סגור',
  total: 'סך הכל',
  hour: 'שעה',
  yes: 'כן',
  no: 'לא',
  in: 'ב',
  and: 'ו',
  error: {
    message: 'חלה שגיאה בנסיון לשמור את הנתונים.{1}',
    action: 'לחץ לריענון המסך',
    number: ' שגיאה {1}',
  },
  days: {
    sunday: 'ראשון',
    monday: 'שני',
    tuesday: 'שלישי',
    wednesday: 'רביעי',
    thursday: 'חמישי',
    friday: 'שישי',
    saturday: 'שבת',
  },
  months: {
    january: 'ינואר',
    february: 'פברואר',
    march: 'מרץ',
    april: 'אפריל',
    may: 'מאי',
    june: 'יוני',
    july: 'יולי',
    august: 'אוגוסט',
    september: 'ספטמבר',
    october: 'אוקטובר',
    november: 'נובמבר',
    december: 'דצמבר',
  },
  family: {
    single: 'רווק/ה',
    married: 'נשוי/אה',
    divorced: 'גרוש/ה',
    widowed: 'אלמן/ה',
  },
  yesNo: {
    yes: 'כן',
    no: 'לא',
  },
  status: {
    active: 'פעיל',
    inactive: 'ארכיון',
  },
  valid: {
    inspire: 'בתוקף',
    expire: 'לא בתוקף',
  },
  process: {
    success: {
      message: 'עיבוד הנתונים עבור הקובץ {1} עבר בהצלחה',
      title: 'הצלחה בעיבוד הנתונים',
    },
    failure: {
      title: 'כשלון בעיבוד הנתונים',
      message: 'עיבוד הנתונים עבור הקובץ {1} נכשל',
    },
  },
  accordType: {
    employee: 'שכיר',
    independent: 'עצמאי',
  },
};
