import PropTypes from 'prop-types';
import { Button, Loader, Menu } from '@mantine/core';
import { IconDownload, IconFileTypePdf, IconFileTypeXls } from '@tabler/icons-react';
import prepareTranslate from '@/helpers/dictionary';
import { useDisclosure } from '@mantine/hooks';

const TYPES = {
  pdf: {
    title: 'Pdf',
    icon: <IconFileTypePdf />,
  },
  excel: {
    title: 'Excel',
    icon: <IconFileTypeXls />,
  },
};

const Export = (props) => {
  const {
    onExportSubmit = () => {},
    options,
    tableLoading,
  } = props;

  const [loading, { open, close }] = useDisclosure(false);

  const t = prepareTranslate();

  const clickOnFile = async (e) => {
    const { currentTarget } = e;
    const { option } = currentTarget.dataset;

    const dataToExport = {
      options: [option],
    };

    open();
    await onExportSubmit(option, dataToExport);
    close();
  };

  const exportOptions = options || ['pdf', 'excel'];

  const items = exportOptions.map((option) => {
    const item = TYPES[option];

    if (!item) {
      return null;
    }

    const { title, icon } = item;

    return (
      <Menu.Item
        key={option}
        data-option={option}
        onClick={clickOnFile}
        leftSection={icon}
      >
        {title}
      </Menu.Item>
    );
  });

  const leftSection = loading ? (
    <Loader size={15}/>
  ) : (
    <IconDownload stroke="1" />
  );

  const isDisabled = tableLoading || loading;

  return (
    <Menu shadow="md">
      <Menu.Target>
        <Button
          color="gray"
          size="xs"
          variant="outline"
          leftSection={leftSection}
          disabled={isDisabled}
        >
          {t('components.export.action')}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>{t('components.export.label')}</Menu.Label>
        {items}
      </Menu.Dropdown>
    </Menu>
  );
};

Export.propTypes = {
  options: PropTypes.array,
  onExportSubmit: PropTypes.func.isRequired,
  exportData: PropTypes.object.isRequired,
  tableLoading: PropTypes.bool,
};

export default Export;
