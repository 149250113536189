import { useQuery } from '@tanstack/react-query';
import { getOneById } from '../requests/agencyCommissions';

const KEY = 'agencyCommissions';

const agencyCommissionsQuery = {
  useGetAgencyCommissionsById: (data = {}, headers = {}) => useQuery({
    queryKey: [KEY, data],
    queryFn: () => getOneById(data, headers),
  }),
};
export default agencyCommissionsQuery;
