import PageHead from '@/components/PageHead';
import prepareTranslate from '@/helpers/dictionary';

function Head() {
  const t = prepareTranslate();

  return (
    <>
      <PageHead
        title={t('pages.agencyCommissionsCreate.title')}
      />
    </>
  );
}

export default Head;
