import PageHead from '@/components/PageHead';
import prepareTranslate from '@/helpers/dictionary';
import { Container, Grid } from '@mantine/core';
import Box from '@/components/Box';
import companyQuery from '@/api/queries/company';
import authQuery from '@/api/queries/auth';

const Main = () => {
  const t = prepareTranslate();

  const { data: userData } = authQuery.useProfile();

  companyQuery.useGetAll();

  const firstName = userData?.firstName;

  return (
    <>
      <PageHead
        title={t('pages.main.title', [firstName])}
        subtitle={t('pages.main.subtitle')}
      />

      <Container fluid>
        <Grid>
          <Grid.Col span={6}>
            <Box
              title='התפתחות תיק'
              subtitle='צפייה'
            >
              box content
            </Box>
          </Grid.Col>
          <Grid.Col span={6}>
            <Box
              title='התפתחות הכנסות'
              subtitle='צפייה'
            >
              box content
            </Box>
          </Grid.Col>
          <Grid.Col>
            <Box
              title='מידע'
            >
              box content
            </Box>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

export default Main;
