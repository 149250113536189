import Comments from '@/components/Comments';
import prepareTranslate from '@/helpers/dictionary';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageTemplate from '@/components/PageTemplate';

function Template(props) {
  const {
    children,
  } = props;

  const t = prepareTranslate();
  const { id } = useParams();

  const tabs = [
    {
      title: t('pages.agencyCommissionsView.side.comments'),
      content: <Comments type='agencyCommissions' id={id} showForm />,
      isDefault: true,
    },
  ];

  return (
    <PageTemplate
    tabs={tabs}
    tabsTitle={t('pages.agencyCommissionsView.side.tabs')}
    actionsTitle={t('pages.agencyCommissionsView.side.actions')}
  >
    {children}
  </PageTemplate>
  );
}

Template.propTypes = {
  children: PropTypes.object,
};

export default Template;
