import PageHead from '@/components/PageHead';
import prepareTranslate from '@/helpers/dictionary';
import routes from '@/routes';
import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

function Head() {
  const t = prepareTranslate();
  const navigate = useNavigate();

  const handleUpdateClick = () => {
    navigate(routes.agencyCommissionsCreate.path);
  };

  return (
    <PageHead
      title={t('pages.agencyCommissions.title')}
      actions={
        <Button onClick={handleUpdateClick} leftSection={<IconPlus />}>
          {t('pages.agencyCommissions.action')}
        </Button>
      }
    />
  );
}

export default Head;
