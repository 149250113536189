import prepareTranslate from '@/helpers/dictionary';
import agentMutations from '@/api/mutitations/agent';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import routes from '@/routes';
import { routeWithParams } from '@/helpers/route';
import { IconCheck } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useForm } from '@mantine/form';
import PageHead from '@/components/PageHead';
import PageTemplate from '@/components/PageTemplate';
import uploadMutations from '@/api/mutitations/upload';
import agentEditValidations from '@/helpers/validation/pages/agentEdit';
import { useState } from 'react';
import CreateContracts from '@/components/CreateContracts';
import CreateClients from '@/components/CreateClients';
import CreateAgentDetails from '@/components/CreateAgentDetails';
import validations from '@/helpers/validation/index';
import Section from '@/components/Section';
import Files from '@/components/Files';

const AgentCreate = () => {
  const t = prepareTranslate();
  const navigate = useNavigate();
  const [loading, { open, close }] = useDisclosure(false);
  const [files, setFiles] = useState([]);

  const { useUpload } = uploadMutations;
  const { mutateAsync: uploadMutate } = useUpload();
  const { useCreateAgent } = agentMutations;
  const { mutateAsync } = useCreateAgent();

  const form = useForm({
    initialValues: {
      personalDetails: {
        children: [],
      },
      agreements: [],
      clients: [],
    },
    validate: {
      status: (value) => validations.required(value),
      agentType: (value) => validations.required(value),
      employeeType: (value) => validations.required(value),
      personalDetails: {
        firstName: (value) => validations.required(value),
        lastName: (value) => validations.required(value),
        uid: (value) => validations.required(value) || validations.uid(value),
        gender: (value) => validations.required(value) || validations.required(value),
        dob: (value) => validations.required(value),
        email: (value) => value && validations.email(value),
        phone: (value) => value && validations.phone(value),
        extraPhone: (value) => value && validations.phone(value),
        homeZipCode: (value) => value && validations.number(Number(value)),
        homePhone: (value) => value && validations.phone(value),
        homePostalCode: (value) => value && validations.number(Number(value)),
        officeZipCode: (value) => value && validations.number(Number(value)),
        officePostalCode: (value) => value && validations.number(Number(value)),
        children: {
          childrenName: (value) => validations.required(value),
        },
      },
      agreements: {
        agentNumber: (value) => validations.required(value),
        from: (value) => validations.required(value),
        companyId: (value) => validations.required(value),
        branchId: (value) => validations.required(value),
        accordType: (value) => validations.required(value),
      },
      clients: {
        companyId: (_, values, path) => agentEditValidations.companyOrHp(values, path),
        hp: (_, values, path) => agentEditValidations.companyOrHp(values, path),
        accordType: (value) => validations.required(value),
        clientNumber: (value) => validations.required(value),
        agentCommission: (value) => validations.required(value),
      },
    },
  });

  // eslint-disable-next-line consistent-return
  const handleAgentCreateClick = async (agentValues) => {
    try {
      open();

      const newAgent = await mutateAsync(agentValues);

      if (!newAgent) {
        notifications.show({
          title: t('pages.agentcreate.notification.failure.title'),
          message: t('pages.agentcreate.notification.failure.message'),
        });
        close();
        return null;
      }
      const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
      const userCookie = cookies.find((cookie) => cookie.startsWith('userInfo=')) || '';
      const userCookieValue = { entity: 'agent', entityId: newAgent.id, ...JSON.parse(decodeURIComponent(userCookie.replace('userInfo=', ''))) };

      if (files.length > 0) {
        try {
          const formData = new FormData();

          const fillterdFiles = files.filter((file) => !file._id);

          fillterdFiles.forEach((file) => {
            formData.append('files', file, file?.name);
          });

          formData.append('user', JSON.stringify(userCookieValue));
          await uploadMutate(formData);
        } catch (error) {
          notifications.show({
            title: t('pages.agentEdit.notification.failure.title'),
            message: t('pages.agentEdit.notification.failure.message'),
          });
          close();
        }
      }

      notifications.show({
        title: t('pages.agentcreate.notification.success.title'),
        message: t('pages.agentcreate.notification.success.message'),
      });

      const { id } = newAgent;
      navigate(routeWithParams(routes.agentView.path, { id }));

      close();
    } catch (error) {
      notifications.show({
        title: t('pages.agentcreate.notification.failure.title'),
        message: t('pages.agentcreate.notification.failure.message'),
      });
      close();
    }
  };
  const handleDelete = (fileName) => {
    const fillterArray = files.filter((item) => (
      item.name !== fileName && item.fileName !== fileName
    ));
    setFiles(fillterArray);
  };

  const handleAccept = (uploadFiles) => {  // eslint-disable-line
    setFiles([...files, ...uploadFiles]);
  };

  const handleAgentCancelClick = () => {
    navigate(routes.agents.path);
  };

  const menuGroups = [
    {
      links: [
        {
          id: 'details',
          title: t('pages.agentcreate.menu.details'),
        },
        {
          id: 'contracts',
          title: t('pages.agentcreate.menu.contracts'),
        },
        {
          id: 'documents',
          title: t('pages.agentcreate.menu.documents'),
        },
        {
          id: 'clients',
          title: t('pages.agentcreate.menu.clients'),
        },
      ],
    },
  ];

  const onError = () => {
    open();
    notifications.show({
      title: t('pages.agentcreate.notification.validtion.title'),
      message: t('pages.agentcreate.notification.validtion.message'),
    });
    close();
  };

  const actions = [
    <Button
      key='primary'
      loading={loading}
      leftSection={<IconCheck />}
      onClick={form.onSubmit(handleAgentCreateClick, onError)}
    >
      {t('global.save')}
    </Button>,
    <Button key='cancel' variant='outline' onClick={handleAgentCancelClick}>
      {t('global.cancel')}
    </Button>,
  ];

  return (
    <>
      <PageHead title={t('pages.agentcreate.title')} />

      <PageTemplate
        menuGroups={menuGroups}
        actions={actions}
        actionsTitle={t('pages.agentcreate.side.actions')}
      >

        <CreateAgentDetails form={form}/>

        <CreateContracts form={form}/>

        <PageTemplate.Panel id='documents'>
          <Section title={t('pages.agentEdit.headers.documents')}>
            <Files
              setFiles={setFiles}
              files={files}
              handleAccept={handleAccept}
              handleDelete={handleDelete}
            />
          </Section>
        </PageTemplate.Panel>

        <CreateClients form={form}/>
     </PageTemplate>
    </>
  );
};

export default AgentCreate;
