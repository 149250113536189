import PropTypes from 'prop-types';
import { IconFilter } from '@tabler/icons-react';
import { MonthPickerInput } from '@mantine/dates';
import prepareTranslate from '@/helpers/dictionary';
import { isObject, isObjectEmpty } from '@/helpers/is';
import { useClickOutside } from '@mantine/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createArraysFromRange } from '@/helpers/dates';
import { useState, Fragment, useEffect } from 'react';
import {
  Badge,
  Button,
  Divider,
  Group,
  MultiSelect,
  NumberInput,
  Popover,
  ScrollArea,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { getCleanQuery } from '../Table.functions';

const Filter = (props) => {
  const {
    columns = [],
    onFilterSubmit = () => {},
    onFilterResetAll = () => {},
    prefix = '',
    query = {},
    setQuery = () => {},
    updateUrl = false,
  } = props;

  const { query: urlQuery } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const t = prepareTranslate();
  const [disabled, setDisabled] = useState(true);
  const [opened, setOpened] = useState(false);
  const ref = useClickOutside(() => setOpened(false));
  const queryCount = (isObject(query) && Object.keys(query).length)
    ? <Badge variant="transparent" color="blue" size="xs">({Object.keys(query).length})</Badge>
    : null;

  const handleUrlUpdate = () => {
    const cleanQuery = getCleanQuery(query);
    const { date, company, product } = cleanQuery;
    const [year, month] = createArraysFromRange(...date);
    const formattedQuery = {
      year,
      month,
      company,
      product,
      agent: [],
    };
    const queryJson = JSON.stringify(formattedQuery);
    const currentPath = decodeURIComponent(location.pathname);
    const newUrl = currentPath.replace(urlQuery, queryJson);
    navigate(newUrl, { replace: true });
  };

  const handleFilterClick = () => {
    if (updateUrl) {
      handleUrlUpdate();
    }

    setOpened(false);
    onFilterSubmit();
  };

  const handleResetAllClick = () => {
    const newQuery = {};
    setQuery(newQuery);
    setOpened(false);
    onFilterResetAll(newQuery);
  };

  const handleResetClick = (e) => {
    const { currentTarget } = e;
    const { id } = currentTarget.dataset;

    setQuery((current) => {
      const state = { ...current };
      delete state[id];
      return state;
    });
  };

  const handleChangeSelect = (value, option, id) => {
    setQuery((current) => {
      const state = { ...current };

      if (value === '') {
        delete state[id];
      } else {
        state[id] = value;
      }
      return state;
    });
  };

  const handleChange = (event) => {
    const { id, value } = event.currentTarget;

    setQuery((current) => {
      const state = { ...current };

      if (value === '') {
        delete state[id];
      } else {
        state[id] = value;
      }
      return state;
    });
  };

  const handleDateChange = (value) => {
    setQuery((current) => {
      const state = { ...current };
      state.filter_date = value;
      return state;
    });
  };

  const handleMultiSelectChange = (value, id) => {
    setQuery((current) => {
      const state = { ...current };
      state[id] = value;
      return state;
    });
  };

  useEffect(() => {
    setDisabled(isObjectEmpty(query));
  }, [query, columns]);

  const filterInput = (id, param, values, data) => {
    let output = null;
    const value = query[id] || '';

    switch (param) {
      case 'select':
        output = <Select
          id={id}
          name={id}
          data={values}
          comboboxProps={{ withinPortal: false }}
          onChange={(selectedValue, option) => handleChangeSelect(selectedValue, option, id)}
          // The reason for this term is to reset the value the user see in the select box when
          // he clicks reset
          value={value === '' ? null : value}
        />;
        break;

      case 'number':
        output = <NumberInput
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
        />;
        break;

      case 'date':
        output = (
          <Group grow>
            <MonthPickerInput
              type="range"
              onChange={handleDateChange}
              value={value}
              popoverProps={{ withinPortal: false }}
            />
          </Group>
        );
        break;

      case 'multi':
        output = (
          <Group grow>
           <MultiSelect
            data={data}
            value={value}
            onChange={(onChangeValue) => handleMultiSelectChange(onChangeValue, id)}
            comboboxProps={{ withinPortal: false }}
          />
          </Group>
        );
        break;

      default: // string
        output = <TextInput
          id={id}
          name={id}
          value={value}
          onChange={handleChange}
        />;
        break;
    }

    return output;
  };

  const filters = columns.map((item) => {
    const {
      header = '',
      accessor = '',
      isVisible = true,
      filter = {},
      data = [],
      onChange = () => {},
    } = item;

    const {
      enable = true,
      type = 'string',
      values = [],
    } = filter;

    const id = `${prefix}${accessor}`;

    if (!enable || !isVisible || isObjectEmpty(filter)) {
      return null;
    }

    return (
      <Fragment key={accessor}>
        <Group justify="space-between">
          <Text fw={500} htmlFor={id} fz="sm">{header}</Text>
          <Button
            data-id={id}
            size="xs"
            variant="transparent"
            onClick={handleResetClick}
          >
            {t('components.filter.reset')}
          </Button>
        </Group>
        {filterInput(id, type, values, data, onChange)}
        <Divider my="lg" />
      </Fragment>
    );
  });

  return (
    <Popover shadow="md" position="bottom" opened={opened}>
      <Popover.Target>
        <Group>
          <Button
            color="gray"
            size="xs"
            variant="outline"
            leftSection={<IconFilter stroke="1" />}
            onClick={() => setOpened((o) => !o)}
          >
            {t('components.filter.action')}
            {queryCount}
          </Button>
        </Group>
      </Popover.Target>
      <Popover.Dropdown w={300}>
        <div ref={ref}>
          <ScrollArea>
            {filters}
          </ScrollArea>
          <Group justify="space-between">
            <Button onClick={handleFilterClick} disabled={disabled}>{t('components.filter.form.action')}</Button>
            <Button variant="subtle" disabled={disabled} onClick={handleResetAllClick}>{t('components.filter.form.reset')}</Button>
          </Group>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

Filter.propTypes = {
  columns: PropTypes.array,
  onFilterSubmit: PropTypes.func,
  onFilterResetAll: PropTypes.func,
  prefix: PropTypes.string,
  query: PropTypes.object,
  setQuery: PropTypes.func,
  updateUrl: PropTypes.bool,
};

export default Filter;
