export default {
  title: 'עריכת עמלות סוכנות',
  form: {
    company: 'חברה',
    product: 'יצרן',
    agencyFeeMonthlyDeposit: 'עמלת סוכנות נפרעים מהפקדה',
    agencyFeeMonthlyAccumulation: 'עמלת סוכנות נפרעים מצבירה',
    agencyFeeAnnualDeposit: 'עמלת סוכנות היקף מהפקדה',
    agencyFeeAnnualAccumulation: 'עמלת סוכנות היקף מצבירה',
  },
  notification: {
    success: {
      title: 'הפרטים עודכנו בהצלחה',
      message: 'פרטי עמלות הסוכנות עודכנו במערכת',
    },
    failure: {
      title: 'הפרטים לא עודכנו',
      message: 'אירעה שגיאה בניסיון העדכון',
    },
    validtion: {
      title: 'הפרטים לא עודכנו',
      message: 'אחד מהפרטים שהוזנו איזו תקין',
    },
  },
  side: {
    tabs: 'מידע נוסף',
    comments: 'הערות',
    histories: 'היסטוריה',
    actions: 'פעולות',
  },
};
