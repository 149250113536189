export default {
  action: 'הוסף משתמש',
  fileTitle: 'משתמשים',
  notification: {
    title: 'המשתמש הופעל בהצלחה',
    message: 'פרטי המשתמש עודכנו במערכת',
    errors: {
      find: {
        title: 'שגיאה',
        message: 'לא ניתן למצוא משתמשים אנא נסה שנית',
      },
      delete: {
        title: 'שגיאה',
        message: 'לא ניתן למחוק משתמשים אנא נסה שנית',
      },
      export: {
        title: 'שגיאה',
        message: 'לא ניתן לייצא דו"ח',
      },
    },
  },
};
