import { headers as defaultHeaders } from '@/config/axios';
import axiosRequest from '.';

const upload = async (data) => (
  axiosRequest({
    data,
    url: '/v1/upload',
    method: 'POST',
  })
);

const getUploadsById = async (data) => (
  axiosRequest({
    url: '/v1/upload/userUploads',
    method: 'POST',
    data,
    headers: defaultHeaders,
  })
);

const getAllUploads = async (data) => (
  axiosRequest({
    url: '/v1/upload/getall',
    method: 'POST',
    data,
    headers: defaultHeaders,
  })
);

const download = async (data) => (
  axiosRequest({
    url: '/v1/upload/download',
    method: 'POST',
    data,
    headers: defaultHeaders,

  })
);

const deleteUpload = async (data) => (
  axiosRequest({
    url: '/v1/upload/delete',
    method: 'POST',
    data,
    headers: defaultHeaders,
  })
);

export {
  upload,
  getUploadsById,
  download,
  getAllUploads,
  deleteUpload,
};
