import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const RESULT_GET_ALL = {
  default: {
    response: {
      createdAt: 1721227697689,
      object: 'getall',
      success: true,
      data: [
        {
          _id: '6617d5b437d01fce576453cb',
          name: 'Pension',
          hebrew: 'פנסיה',
        },
        {
          _id: '666fe3838b5154bf71df8533',
          name: 'Savings policy',
          hebrew: 'פוליסת חסכון',
        },
        {
          _id: '6617d5db37d01fce576453cc',
          name: 'Provident',
          hebrew: 'גמל',
        },
        {
          _id: '669cc5f9b47f9bbe0a762114',
          name: 'Risk',
          hebrew: 'חיים',
        },
        {
          _id: '669d10fdceeb2518f5269f1d',
          name: 'Social loans',
          hebrew: 'הלוואות חברתיות',
        },
        {
          _id: '66aa4144b0034aab211acd30',
          name: 'Disability',
          hebrew: 'אובדן כושר עבודה',
        },
        {
          _id: '66b1f5967fa7f8b8b8a83141',
          name: 'Health',
          hebrew: 'בריאות',
        },
        {
          _id: '66b1f5e37fa7f8b8b8a83142',
          name: 'Managers',
          hebrew: 'מנהלים',
        },
        {
          _id: '66b1f60c7fa7f8b8b8a83143',
          name: 'Insurance umbrella',
          hebrew: 'מטריה ביטוחית',
        },
        {
          _id: '66b1f6a07fa7f8b8b8a83144',
          name: 'children\'s savings',
          hebrew: 'חיסכון ילד',
        },
        {
          _id: '66ab4d8ab0034aab211acd3c',
          name: 'Immediate allowance',
          hebrew: 'קצבה מיידית',
        },
        {
          _id: '66d43406ef64e3015bc42f08',
          name: 'Case management',
          hebrew: 'ניהול תיקים',
        },
      ],
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1720349117033,
      error: {
        collection: 'product',
        code: 500,
        error: '',
        message: 'אירעה שגיאה',
      },
      object: 'getall',
      success: false,
    },
    status: 500,
  },
};

const getAllProductsHandler = async () => {
  const { response, status } = getResponseData(RESULT_GET_ALL);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export {
  getAllProductsHandler,
};
