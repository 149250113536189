import { MIME_TYPES } from '@mantine/dropzone';
import routes from '@/routes';
import prepareTranslate from './dictionary';

const t = prepareTranslate();

// TODO: change text to dictionary
const MESSAGE_TYPES = Object.freeze([
  { value: routes.agentView.path, label: 'סוכן' },
  { value: routes.userView.path, label: 'משתמש' },
]);

const ROLE_LIST = Object.freeze([
  { value: 'superadmin', label: 'superadmin' },
  { value: 'administrator', label: 'administrator' },
]);

const USER_STATUS = Object.freeze([
  { value: 'active', label: t('pages.useredit.status.active') },
  { value: 'archived', label: t('pages.useredit.status.archive') },
]);

const AGENT_STATUS = Object.freeze([
  { value: 'active', label: t('pages.agentEdit.status.active') },
  { value: 'archive', label: t('pages.agentEdit.status.archive') },
]);

const YES_NO = Object.freeze([
  { value: 'yes', label: t('global.yesNo.yes') },
  { value: 'no', label: t('global.yesNo.no') },
]);

const AGENT_TYPE = Object.freeze([
  { value: 'CTO', label: t('pages.agentEdit.agentType.CTO') },
  { value: 'houseAgent', label: t('pages.agentEdit.agentType.houseAgent') },
  { value: 'integratedAgent', label: t('pages.agentEdit.agentType.integratedAgent') },
  { value: 'foreignAgent', label: t('pages.agentEdit.agentType.foreignAgent') },
]);

const EMPLOYEE_TYPE = Object.freeze([
  { value: 'employee', label: t('pages.agentEdit.employeeType.employee') },
  { value: 'selfEmployee', label: t('pages.agentEdit.employeeType.selfEmployee') },
]);

const GENDER = Object.freeze([
  { value: 'male', label: t('components.createAgentDetails.male') },
  { value: 'female', label: t('components.createAgentDetails.female') },
]);

const FAMILY_STATUS = Object.freeze([
  { value: 'single', label: t('global.family.single') },
  { value: 'married', label: t('global.family.married') },
  { value: 'divorced', label: t('global.family.divorced') },
  { value: 'widowed', label: t('global.family.widowed') },
]);

const ACCORD_TYPE = Object.freeze([
  { value: 'independent', label: t('global.accordType.independent') },
  { value: 'employee', label: t('global.accordType.employee') },
]);

const COLORS = Object.freeze([
  'red',
  'grape',
  'violet',
  'indigo',
  'cyan',
  'teal',
  'lime',
  'yellow',
]);

const FORM_ERRORS = Object.freeze({
  required: 'שדה חובה',
  fileTitleInvalid: 'שם הקובץ שנבחר אינו תואם את שמות הקבצים הנדרשים',
});

const FILE_ERRORS = Object.freeze({
  'file-invalid-type': 'סוג המסמך לא תואם את הסוג הנדרש',
  'file-too-large': 'המסמך גדול מדי',
});

const DIRECTIONS = Object.freeze({
  asc: 'asc',
  desc: 'desc',
});

const CHART_TYPES = Object.freeze({
  line: 'Line',
  bar: 'Bar',
});

const SSE_TYPES = Object.freeze({
  process: 'process',
});

const CUSTOM_MIME_TYPES = {
  png: { mimeType: MIME_TYPES.png, extensions: ['.png'] },
  jpeg: { mimeType: MIME_TYPES.jpeg, extensions: ['.jpeg'] },
  svg: { mimeType: MIME_TYPES.svg, extensions: ['.svg'] },
  gif: { mimeType: MIME_TYPES.gif, extensions: ['.gif'] },
  pdf: { mimeType: MIME_TYPES.pdf, extensions: ['.pdf'] },
  pptx: { mimeType: MIME_TYPES.pptx, extensions: ['.pptx'] },
  ppt: { mimeType: MIME_TYPES.ppt, extensions: ['.ppt'] },
  xlsx: { mimeType: MIME_TYPES.xlsx, extensions: ['.xlsx'] },
  xls: { mimeType: MIME_TYPES.xls, extensions: ['.xls'] },
  docx: { mimeType: MIME_TYPES.docx, extensions: ['.docx'] },
  doc: { mimeType: MIME_TYPES.doc, extensions: ['.doc'] },
  xlsb: { mimeType: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12', extensions: ['.xlsb'] },
};

export {
  MESSAGE_TYPES,
  AGENT_STATUS,
  FAMILY_STATUS,
  FORM_ERRORS,
  FILE_ERRORS,
  ROLE_LIST,
  AGENT_TYPE,
  GENDER,
  USER_STATUS,
  DIRECTIONS,
  COLORS,
  EMPLOYEE_TYPE,
  YES_NO,
  CHART_TYPES,
  SSE_TYPES,
  ACCORD_TYPE,
  CUSTOM_MIME_TYPES,
};
