import PageHead from '@/components/PageHead';
import prepareTranslate from '@/helpers/dictionary';
import { routeWithParams } from '@/helpers/route';
import routes from '@/routes';
import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

function Head(props) {
  const {
    id,
  } = props;

  const t = prepareTranslate();

  const navigate = useNavigate();

  const handleUpdateClick = () => {
    navigate(routeWithParams(routes.agencyCommissionsEdit.path, { id }));
  };

  return (
    <>
      <PageHead
        title={t('pages.agencyCommissionsView.title')}
        actions={
          <Button onClick={handleUpdateClick} leftSection={<IconEdit />}>
            {t('pages.agencyCommissionsView.action')}
          </Button>
        }
      />
    </>
  );
}

Head.propTypes = {
  id: PropTypes.string,
};

export default Head;
