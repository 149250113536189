export default {
  clientNumber: 'מספר תיק',
  company: 'חברה',
  hp: 'ח.פ',
  agentCommission: 'עמלת סוכן',
  accordType: 'סוג הסכם',
  headers: {
    clients: 'תיקים',
  },
};
