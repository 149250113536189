import PropTypes from 'prop-types';
import clsx from 'clsx';
import prepareTranslate from '@/helpers/dictionary';
import routes from '@/routes';
import {
  Avatar,
  Divider,
  Indicator,
  Menu,
  Text,
} from '@mantine/core';
import { IconTrash, IconUsers } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import authQuery from '@/api/queries/auth';
import authApi from '@/api/services/auth';
import styles from './style.module.css';
// TODO: bring back messages menu action

const firstChar = (val) => val && Array.from(val)[0];

const UserIcon = (props) => {
  const {
    isMenuActive = true,
    showOnlyDetails = false,
    variant = 'light',
  } = props;

  const { data: userData } = authQuery.useProfile();

  const firstName = userData?.firstName;
  const lastName = userData?.lastName;
  const email = userData?.email;
  // eslint-disable-next-line no-unused-vars
  const isNewMessages = true;

  const t = prepareTranslate();
  const navigate = useNavigate();
  const short = `${firstChar(firstName)}${firstChar(lastName)}`;
  const full = `${firstName} ${lastName}`;
  const avatarClasses = clsx(isMenuActive && styles.menu);
  const IndicatorDisabled = true; // !isMenuActive || showOnlyDetails || !isNewMessages;

  const clickOnUsers = () => { navigate(routes.users.path); };
  // const clickOnMessages = () => { navigate(routes.messages.path); };

  const clickOnLogout = () => {
    authApi.logout();
    navigate(routes.login.path);
  };

  const actionIcon = (
    <Indicator
      disabled={IndicatorDisabled}
      offset={5}
      processing
      color="green"
      style={{ display: 'inline-block' }}
    >
      <Avatar
        radius="xl"
        variant={variant}
        className={avatarClasses}
        color="blue"
      >
        {short}
      </Avatar>
    </Indicator>
  );

  if (!isMenuActive) {
    return actionIcon;
  }

  return (
    <Menu>
      <Menu.Target>
        {actionIcon}
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item>
          <Text fw={500}>{full}</Text>
          <Text size="xs" c="dimmed">{email}</Text>
        </Menu.Item>

        {!showOnlyDetails && (
          <>
            <Divider my="lg" />
            <Menu.Label>{t('components.usericon.labels.options')}</Menu.Label>
            <Menu.Item
              leftSection={<IconUsers width="1rem" stroke={1.5} />}
              onClick={clickOnUsers}
            >
              {t('components.usericon.items.users')}
            </Menu.Item>
            {/* <Menu.Item
              leftSection={
                <Indicator disabled={IndicatorDisabled} offset={3} processing color="green">
                  <IconBell width="1rem" stroke={1.5} />
                </Indicator>
              }
              onClick={clickOnMessages}
            >
              {t('components.usericon.items.messages')}
            </Menu.Item> */}

            <Menu.Label>{t('components.usericon.labels.actions')}</Menu.Label>
            <Menu.Item
              color="red"
              leftSection={<IconTrash width="1rem" stroke={1.5} />}
              onClick={clickOnLogout}
            >
              {t('components.usericon.items.logout')}
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

UserIcon.propTypes = {
  userId: PropTypes.string,
  isMenuActive: PropTypes.bool,
  showOnlyDetails: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'filled']),
};

export default UserIcon;
