import PropTypes from 'prop-types';
import { IconTrash } from '@tabler/icons-react';
import {
  ActionIcon,
  Button,
  Grid,
  Select,
  TextInput,
} from '@mantine/core';
import prepareTranslate from '@/helpers/dictionary';
import {
  AGENT_STATUS,
  AGENT_TYPE,
  EMPLOYEE_TYPE,
  FAMILY_STATUS,
  GENDER,
} from '@/helpers/enums';
import Section from '../Section';
import PageTemplate from '../PageTemplate';

const CreateAgentDetails = (props) => {
  const {
    form,
  } = props;

  const t = prepareTranslate();

  // TO DO REMOVE THAT
  const today = new Date();
  const twentyYearsAgo = new Date(
    today.getFullYear() - 20,
    today.getMonth(),
    today.getDate(),
  );

  return (
    <form>
    <PageTemplate.Panel id='details'>
      <Section title={t('components.createAgentDetails.headers.private')}>
        <Grid gutter='lg'>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label={t('components.createAgentDetails.status')}
              data={AGENT_STATUS}
              required
              key={form.key('status')}
              {...form.getInputProps('status')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label={t('components.createAgentDetails.agentType')}
              data={AGENT_TYPE}
              required
              key={form.key('agentType')}
              {...form.getInputProps('agentType')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label={t('components.createAgentDetails.employeeType')}
              data={EMPLOYEE_TYPE}
              required
              key={form.key('employeeType')}
              {...form.getInputProps('employeeType')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.uid')}
              required
              key={form.key('uid')}
              {...form.getInputProps('personalDetails.uid')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.firstname')}
              required
              key={form.key('firstName')}
              {...form.getInputProps('personalDetails.firstName')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.lastname')}
              required
              key={form.key('lastName')}
              {...form.getInputProps('personalDetails.lastName')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              maxDate={twentyYearsAgo}
              autoComplete='off'
              label={t('components.createAgentDetails.dob')}
              required
              key={form.key('personalDetails.dob')}
              {...form.getInputProps('personalDetails.dob')}
              onChange={(e) => {
                const v = e.target.value
                  .replace(/^(\d\d)(\d)$/g, '$1/$2')
                  .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                  .replace(/[^\d/]/g, '');
                form.setFieldValue('personalDetails.dob', v);
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label={t('components.createAgentDetails.gender')}
              data={GENDER}
              key={form.key('gender')}
              required
              {...form.getInputProps('personalDetails.gender')}
            />
          </Grid.Col>
        </Grid>
      </Section>

      <Section title={t('components.createAgentDetails.headers.contact')}>
        <Grid gutter='lg'>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.email')}
              key={form.key('email')}
              {...form.getInputProps('personalDetails.email')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.cellphone')}
              key={form.key('phone')}
              {...form.getInputProps('personalDetails.phone')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.extracellphone')}
              maxLength={10}
              key={form.key('personalDetails.extraPhone')}
              {...form.getInputProps('personalDetails.extraPhone')}
            />
          </Grid.Col>
        </Grid>
      </Section>

      <Section title={t('components.createAgentDetails.headers.home')}>
        <Grid gutter='lg'>
          <Grid.Col span={{ base: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.city')}
              key={form.key('homeCity')}
              {...form.getInputProps('personalDetails.homeCity')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.street')}
              key={form.key('street')}
              {...form.getInputProps('personalDetails.homeStreet')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.houseNumber')}
              key={form.key('houseNumber')}
              {...form.getInputProps('personalDetails.homeHouseNumber')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.zipCode')}
              key={form.key('homeZipCode')}
              {...form.getInputProps('personalDetails.homeZipCode')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.postalCode')}
              key={form.key('postalCode')}
              {...form.getInputProps('personalDetails.postalCode')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.phone')}
              key={form.key('homePhone')}
              {...form.getInputProps('personalDetails.homePhone')}
            />
          </Grid.Col>
        </Grid>
      </Section>

      <Section title={t('components.createAgentDetails.headers.office')}>
        <Grid gutter='lg'>
          <Grid.Col span={{ base: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.city')}
              key={form.key('officeCity')}
              {...form.getInputProps('personalDetails.officeCity')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.street')}
              key={form.key('officeStreet')}
              {...form.getInputProps('personalDetails.officeStreet')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.houseNumber')}
              key={form.key('officeHouseNumber')}
              {...form.getInputProps('personalDetails.officeHouseNumber')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.zipCode')}
              key={form.key('officeZipCode')}
              {...form.getInputProps('personalDetails.officeZipCode')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.postalCode')}
              key={form.key('officePostalCode')}
              {...form.getInputProps('personalDetails.officePostalCode')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.phone')}
              key={form.key('officePhone')}
              {...form.getInputProps('personalDetails.officePhone')}
            />
          </Grid.Col>
        </Grid>
      </Section>

      <Section title={t('components.createAgentDetails.headers.family')}>
        <Grid gutter='lg'>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Select
              label={t('components.createAgentDetails.familystatus')}
              data={FAMILY_STATUS}
              key={form.key('familyStatus')}
              {...form.getInputProps('personalDetails.familyStatus')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              autoComplete='off'
              label={t('components.createAgentDetails.spouse')}
              key={form.key('spouse')}
              {...form.getInputProps('personalDetails.spouse')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12 }}>
            {form.getValues().personalDetails.children.map((_, index) => (
              <Grid align='center' key={index + 1}>
                <Grid.Col span={{ base: 12, md: 3 }}>
                  <TextInput
                    autoComplete='off'
                    maxLength={25}
                    required
                    label={t('components.createAgentDetails.fullName')}
                    key={form.key(`personalDetails.children.${index}.childrenName`)}
                    {...form.getInputProps(`personalDetails.children.${index}.childrenName`)}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 3 }}>
                  <TextInput
                    // clearable
                    autoComplete='off'
                    maxLength={10}
                    label={t('components.createAgentDetails.dob')}
                    key={form.key(`personalDetails.children.${index}.childrenDob`)}
                    {...form.getInputProps(`personalDetails.children.${index}.childrenDob`)}
                    onChange={(e) => {
                      const v = e.target.value
                        .replace(/^(\d\d)(\d)$/g, '$1/$2')
                        .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                        .replace(/[^\d/]/g, '');
                      form.setFieldValue(`personalDetails.children.${index}.childrenDob`, v);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 1 }}>
                  <ActionIcon
                    mt={25}
                    onClick={() => {
                      form.removeListItem('personalDetails.children', index);
                    }}
                    color={'red'}
                    variant={'transparent'}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            ))}
            <Button
              mt={15}
              mb={15}
              onClick={() => {
                form.insertListItem('personalDetails.children', {
                  childrenName: '',
                  childrenDob: null,
                });
              }}
            >
              {t('components.createAgentDetails.addChildren')}
            </Button>
          </Grid.Col>
        </Grid>
      </Section>

      <Section title={t('components.createAgentDetails.headers.dates')}>
        <Grid gutter='lg'>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              clearable
              autoComplete='off'
              maxLength={10}
              label={t('components.createAgentDetails.policyend')}
              key={form.key('personalDetails.policyEndDate')}
              {...form.getInputProps('personalDetails.policyEndDate')}
              onChange={(e) => {
                const v = e.target.value
                  .replace(/^(\d\d)(\d)$/g, '$1/$2')
                  .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                  .replace(/[^\d/]/g, '');
                form.setFieldValue('personalDetails.policyEndDate', v);
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              clearable
              autoComplete='off'
              maxLength={10}
              label={t('components.createAgentDetails.tax')}
              key={form.key('personalDetails.taxDeductionsDate')}
              {...form.getInputProps('personalDetails.taxDeductionsDate')}
              onChange={(e) => {
                const v = e.target.value
                  .replace(/^(\d\d)(\d)$/g, '$1/$2')
                  .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                  .replace(/[^\d/]/g, '');
                form.setFieldValue('personalDetails.taxDeductionsDate', v);
              }}
            />
          </Grid.Col>
        </Grid>
      </Section>
    </PageTemplate.Panel>
  </form>
  );
};

CreateAgentDetails.propTypes = {
  form: PropTypes.object,
};

export default CreateAgentDetails;
