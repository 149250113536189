import { useMutation } from '@tanstack/react-query';
import {
  createOne,
  deleteMany,
  getAll,
  updateOne,
} from '../requests/agencyCommissions';

const KEY = 'agencyCommissions';

const agencyCommissionsMutation = {
  useGetAll: () => useMutation({
    mutationKey: [KEY, 'getAll'],
    mutationFn: (data) => getAll(data),
  }),
  useUpdateOne: () => useMutation({
    mutationKey: [KEY, 'updateOne'],
    mutationFn: (data) => updateOne(data),
  }),
  useCreateOne: () => useMutation({
    mutationKey: [KEY, 'createOne'],
    mutationFn: (data) => createOne(data),
  }),
  useDeleteMany: () => useMutation({
    mutationKey: [KEY, 'deleteMany'],
    mutationFn: (data) => deleteMany(data),
  }),
};

export default agencyCommissionsMutation;
