export default {
  labels: {
    agentnumber: 'מספר סוכן',
    date: {
      start: 'מתאריך',
      end: 'עד תאריך',
    },
    branch: 'ענפים',
    accordType: 'סוג הסכם',
    commission: {
      agentFeeMonthlyAccumulation: 'עמלת סוכן נפרעים מהפקדה',
      agentFeeMonthlyDeposit: 'עמלת סוכן נפרעים מצבירה',
      agentFeeAnnualAccumulation: 'עמלת סוכן היקף מצבירה',
      agentFeeAnnualDeposit: 'עמלת סוכן היקף מהפקדה',
      agentFee: 'עמלת סוכן',
      noData: 'אין מידע',
    },
  },
};
