import { useMutation } from '@tanstack/react-query';
import {
  updateUser, createUser, getUsersByQuery, getOne,
  deleteManyUsers,
} from '../requests/user';

const KEY = 'user';

const useGetUsers = () => useMutation({
  mutationKey: [KEY, 'getUsers'],
  mutationFn: ({ start, end, query }) => getUsersByQuery({ start, end, query }),
});

const useGetOne = () => useMutation({
  mutationKey: [KEY, 'getOne'],
  mutationFn: (data) => getOne(data),
});

const useCreateUser = () => useMutation({
  mutationKey: [KEY, 'create'],
  mutationFn: (data) => createUser(data),
});

const useDeleteManyUsers = () => useMutation({
  mutationKey: [KEY, 'delete', 'many'],
  mutationFn: (data) => deleteManyUsers(data),
});

const useUpdateUser = () => useMutation({
  mutationKey: [KEY, 'update'],
  mutationFn: (data) => updateUser(data),
});

const userMutations = {
  useGetOne,
  useGetUsers,
  useCreateUser,
  useDeleteManyUsers,
  useUpdateUser,
};

export default userMutations;
