import { getResponseData } from '@/helpers/mocks';
import { HttpResponse, delay } from 'msw';

const RESULT_GET_ALL = {
  default: {
    response: {
      createdAt: 1720348393535,
      error: {
        code: 0,
        error: '',
      },
      object: 'getall',
      success: true,
      data: [
        {
          _id: '65fc141fc676340ed68694f6',
          name: 'Altshuler Shaham',
          hebrew: 'אלטשולר שחם',
          email: 'altshuler@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66045d53e376171abbb826a3',
          name: 'Harel',
          hebrew: 'הראל',
          email: 'harel@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66045d75e376171abbb826a5',
          name: 'Meitav',
          hebrew: 'מיטב',
          email: 'meitav@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66045dcfe376171abbb826a7',
          name: 'Phoenix',
          hebrew: 'פניקס',
          email: 'pheonix@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '6609110becb461e59d78a4b5',
          name: 'Menora',
          hebrew: 'מנורה',
          email: 'menora@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '666fe2a48b5154bf71df852e',
          name: 'Hachshara',
          hebrew: 'הכשרה',
          email: 'hachshara@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '669f90bb3e6df73725bd09e0',
          name: 'Tarya',
          hebrew: 'טריא',
          email: 'tarya@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66a0ad45c850ffdee9a0bf42',
          name: 'Blender',
          hebrew: 'בלנדר',
          email: 'blender@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66a764d6e6b06c44f492b7a7',
          name: 'Clal',
          hebrew: 'כלל',
          email: 'clal@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66a899acb0034aab211acd1f',
          name: 'Analyst',
          hebrew: 'אנליסט',
          email: 'analyst@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66a8a3f4e6b06c44f492b7bf',
          name: 'Migdal',
          hebrew: 'מגדל',
          email: 'migdal@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66a8b395e6b06c44f492b7c0',
          name: 'More',
          hebrew: 'מור',
          email: 'more@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66aa43a0e6b06c44f492b7c3',
          name: 'Ayalon',
          hebrew: 'איילון',
          email: 'ayalon@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66aa55d1e6b06c44f492b7c6',
          name: 'Yelin Lapidot',
          hebrew: 'ילין לפידות',
          email: 'ylapidot@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66bdba22b95eaa0fc7e5a9a0',
          name: 'Equity',
          hebrew: 'אקוויטי',
          email: 'equity@fintek.co.il',
          phone: '0545653650',
        },
        {
          _id: '66cf4ec97849eea62bd36b63',
          name: 'BTB',
          hebrew: 'BTB',
          email: 'btb@fintek.co.il',
          phone: '0545653650',
        },
      ],
    },
    status: 200,
  },

  error: {
    response: {
      createdAt: 1720349117033,
      error: {
        collection: 'companies',
        code: 501,
        error: 'No documnets were found',
        message: 'אירעה שגיאה',
      },
      object: 'getall',
      success: false,
    },
    status: 500,
  },
};

const getAllHandler = async () => {
  const { response, status } = getResponseData(RESULT_GET_ALL);
  await delay(1000);

  return new HttpResponse(
    JSON.stringify(response),
    {
      status,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export {
  getAllHandler,
};
